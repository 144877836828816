/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable rxjs/no-async-subscribe */
/* eslint-disable @typescript-eslint/no-misused-promises */
import { HttpClient } from '@angular/common/http';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  FormControl,
} from '@angular/forms';
import { MessageService } from 'primeng/api';
import { lastValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AdminReapprovalContractStoreService } from '../admin-reapproval-store.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-reaprove-contract-independent-detail',
  templateUrl: './reaprove-contract-independent-detail.component.html',
  styleUrls: ['./reaprove-contract-independent-detail.component.css'],
})
export class ReAproveContractIndependentDetailComponent implements OnInit {
  locality: any = null;
  contract: any = null;
  client: any = null;

  baseConditions: any[] = [];
  extraConditions: any[] = [];
  currentConditions: any[] = [];

  stateOptions: any[] = [
    { label: 'No', value: false },
    { label: 'Si', value: true },
  ];

  form: UntypedFormGroup = new UntypedFormGroup({});

  selectedId = -1;

  calculusOptions: any[] = [
    { label: 'Expediente', value: 0 },
    { label: 'Inspeccion', value: 1 },
    { label: 'Fijo', value: 2 },
  ];
  discountOptions: any[] = [
    { label: 'Fijo', value: 0 },
    { label: 'Porcentaje', value: 1 },
  ];

  discountType: number = -1;
  discountQty: number = 0;
  discountPer: number = 0;

  constructor(
    private store: AdminReapprovalContractStoreService,
    private http: HttpClient,
    private fb: UntypedFormBuilder,
    private messageSvc: MessageService,
    private location: Location,
    private detector: ChangeDetectorRef,
  ) {}

  ngOnInit() {
    this.store.selectedContract$.subscribe(async (contract) => {
      this.detector.detach();
      this.client = contract.client;
      this.contract = contract;
      this.baseConditions = (await this.getBySerConditions()) || [];
      this.extraConditions = (await this.getExtraConditions()) || [];
      this.currentConditions = await this.getCurrentConditions();

      for (const condition of this.extraConditions) {
        const exists =
          this.currentConditions.filter(
            (extra: any) => extra.facturable.id == condition.id,
          ).length > 0;

        this.form.addControl(
          condition.id,
          new FormControl({ value: exists, disabled: true }),
        );

        const currentCondition = this.currentConditions.filter(
          (extra: any) => extra.facturable.id == condition.id,
        )[0];

        let price = 0;
        let factor = 0;

        if (currentCondition) {
          price = currentCondition.qty;
          factor = currentCondition.factor;
        }

        this.form.addControl(
          `${condition.id}_qty`,
          new FormControl({ value: price, disabled: !exists }),
        );
        this.form.addControl(
          `${condition.id}_fac`,
          new FormControl({ value: factor, disabled: !exists }),
        );
        this.form.addControl(
          `${condition.id}_fac_id`,
          new FormControl(condition),
        );
      }

      this.detector.reattach();
      this.detector.detectChanges();
    });
  }

  aproveContract() {
    this.contract.extrasFacturables = this.createExtrasConditions();
    this.contract.discount = this.discountQty;
    this.contract.discountType = this.discountType;

    try {
      this.sendAproval(this.contract).then(() => {
        this.messageSvc.add({
          severity: 'success',
          summary: 'Solicitud enviada',
          detail: 'La solicitud de aprobación ha sido enviada correctamente',
        });
        this.location.back();
      });
    } catch (e) {
      (e);
      this.messageSvc.add({
        severity: 'error',
        summary: 'Solicitud fallida',
        detail: 'La aprobacion de la solicitud ha fallado',
      });
    }
  }

  async rejectContract() {
    try {
      this.sendReject(this.contract).then((_data: any) => {
        this.messageSvc.add({
          severity: 'success',
          summary: 'Solicitud enviada',
          detail: 'La solicitud de rechazo ha sido enviada correctamente',
        });
        this.location.back();
      });
    } catch (e) {
      (e);
      this.messageSvc.add({
        severity: 'error',
        summary: 'Solicitud fallida',
        detail: 'El rechazo de la solicitud ha fallado',
      });
    }
  }

  private createExtrasConditions() {
    const extras = [];

    for (const condition of this.extraConditions) {
      if (this.form.get(`${condition.id}`)?.value == true) {
        if (condition.extraType == 1) {
          extras.push({
            qty: this.form.value[`${condition.id}_qty`] || 0,
            factor: this.form.value[`${condition.id}_fac`],
            facturable: this.form.value[`${condition.id}_fac_id`],
          });
        } else {
          extras.push({
            qty: condition.price,
            factor: 2,
            facturable: this.form.value[`${condition.id}_fac_id`],
          });
        }
      }
    }

    return extras;
  }

  private async getBySerConditions() {
    return await lastValueFrom(
      this.http.get<any>(`${environment.api}contracts/conditions-by-service`),
    );
  }

  private async getExtraConditions() {
    return await lastValueFrom(
      this.http.get<any[]>(
        `${environment.api}contracts/conditions-contract-extras`,
      ),
    );
  }

  private async sendAproval(request: any) {
    return await lastValueFrom(
      this.http.post(
        `${environment.api}contracts/reapprove/independent`,
        request,
      ),
    );
  }

  private async sendReject(request: any) {
    return await lastValueFrom(
      this.http.post(`${environment.api}contracts/reject/independent`, request),
    );
  }

  private async getContractDetails(id: number) {
    return await lastValueFrom(
      this.http.get<any>(
        `${environment.api}contracts/pending/aproval/independent/byid/${id}`,
      ),
    );
  }

  private async getCurrentConditions() {
    return await lastValueFrom(
      this.http.get<any[]>(
        `${environment.api}contracts/conditions-contract-active/byser/${this.contract.id}`,
      ),
    );
  }
}
