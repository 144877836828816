/* eslint-disable @typescript-eslint/unbound-method */
import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import {
  ConfirmationService,
  ConfirmEventType,
  MessageService,
} from 'primeng/api';
import { take, Observable, lastValueFrom } from 'rxjs';
import { UtilsService } from 'src/app/shared/services/utils.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-admin-upsert-clients',
  templateUrl: './admin-upsert-clients.component.html',
  styleUrls: ['./admin-upsert-clients.component.scss'],
})
export class AdminUpsertClientsComponent implements OnInit {
  client?: any;
  loading?: boolean;
  deleteLoading?: boolean;
  types?: string[];
  form = new FormGroup({
    owner_id: new FormControl(),
    cif: new FormControl(null, Validators.required),
    businessName: new FormControl(null, Validators.required),
    ineID: new FormControl(null, Validators.required),
    email: new FormControl(null, [Validators.required, Validators.email]),
    phone: new FormControl(null),
    address: new FormControl(null),
    showInPublic: new FormControl(true),
  });

  recordsINE: any[] = [];

  errors: { [key: string]: string | boolean } = {};

  users: any[] = [];

  owner_id?: string;

  inserting: boolean = false;

  embedded: boolean = false;

  constructor(
    private readonly http: HttpClient,
    private confirmationService: ConfirmationService,
    private router: Router,
    private route: ActivatedRoute,
    private toastService: MessageService,
  ) {}
  async ngOnInit() {
    await this.route.queryParams
      .pipe(take(1))
      .toPromise()
      .then(async (params: any) => {
        if (params.id) {
          await this.loadUser(params.id);
          this.owner_id = params.id;
        } else {
          this.inserting = true;
        }
      });

    this.recordsINE = await lastValueFrom(
      this.http.get<any[]>(environment.api + 'expedientes/localities'),
    );

    (this.recordsINE);
  }

  onChangeINE(e: any) {
    (e);
    if (this.inserting) {
      this.form.patchValue({
        businessName: e.value.name,
        email: e.value.email,
        phone: e.value.phone,
        address: e.value.address,
      });
    }
  }

  async loadUser(id: string) {
    this.loading = true;

    const client = await lastValueFrom(
      this.http.get<any>(environment.api + 'owners/clients/' + id),
    );

    this.client = client;

    (client);

    this.inserting = false;

    this.form.patchValue({
      owner_id: this.owner_id,
      cif: client.cif,
      businessName: client.businessName,
      ineID: client.ineID,
      email: client.email,
      phone: client.phone,
      address: client.address,
      showInPublic: client.showInPublic,
    });

    if (client != null) {
      (client.owner_id);

      this.users = await lastValueFrom(
        this.http.get<any[]>(
          environment.api + 'owners/client/users/' + client.owner_id,
        ),
      );
    }

    this.loading = false;
  }

  async refreshUsers() {
    await this.loadUser(this.owner_id as string);
  }

  async save() {
    if (this.form.valid) {
      this.loading = true;
      const values: any = this.form.value;

      const request: Observable<any> = !this.inserting
        ? this.http.put(environment.api + 'owners/client', values)
        : this.http.post(environment.api + 'owners/client', values);

      await request
        .toPromise()
        .then(async (data: any) => {
          (data);
          this.toastService.add({
            severity: 'success',
            summary: 'Guardado',
            detail: 'El cliente se ha guardado correctamente',
          });
        })
        .catch((e) => {
          if (e.error.message === 'EMAIL_ALREADY_EXIST') {
            this.form.get('email')?.setErrors({ emailAlreadyExist: true });
            this.toastService.add({
              severity: 'error',
              summary: 'Error',
              detail: 'El correo ya existe',
            });
            UtilsService.markGroupControlsAsDirty(this.form);
          }

          if (e.error.message === 'CIF already exists') {
            this.form.get('cif')?.setErrors({ cifAlreadyExist: true });
            this.toastService.add({
              severity: 'error',
              summary: 'Error',
              detail: 'El CIF ya existe',
            });
            UtilsService.markGroupControlsAsDirty(this.form);
          }

          if (e.error.message === 'Ayto already exists') {
            this.form.get('ineID')?.setErrors({ aytoAlreadyExist: true });
            this.toastService.add({
              severity: 'error',
              summary: 'Error',
              detail: 'El Ayuntamiento ya esta asignado a otro cliente',
            });
            UtilsService.markGroupControlsAsDirty(this.form);
          }
        });

      this.loading = false;
    } else {
      UtilsService.markGroupControlsAsDirty(this.form);
    }
  }

  delete() {
    this.confirmationService.confirm({
      accept: async () => {
        this.deleteLoading = true;
        await this.http
          .delete(environment.api + 'owners/clients/' + this.client.id)
          .toPromise()
          .then(async (_data: any) => {
          })
          .catch((error) => {
            (error);
          });
        this.deleteLoading = false;
      },
      reject: (_type: ConfirmEventType) => {},
    });
  }
}
