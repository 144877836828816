import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService, MessageService } from 'primeng/api';
import { Table } from 'primeng/table';
import { lastValueFrom } from 'rxjs';
import { UserService } from 'src/app/shared/services/user.service';
import { environment } from 'src/environments/environment';
import { StoredBillStoreService } from './stored-bill-store.service';

@Component({
  selector: 'app-stored-bills-contracts',
  templateUrl: './stored-bills.component.html',
  styleUrls: ['./stored-bills.component.css'],
})
export class StoredBillsComponent implements OnInit {
  @ViewChild('dt') table?: Table;

  bills?: any[] = [];
  totalRecords?: number;
  loading?: boolean;

  user?: any;

  isAdmin: boolean = false;
  isClient: boolean = false;
  isProvider: boolean = false;

  owner_id?: string;

  _typesFilter: string[] = [];
  set typesFilter(value: string[] | undefined) {
    this._typesFilter = value || [];
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    this.load();
  }
  get typesFilter() {
    return this._typesFilter;
  }

  types?: string[] = [];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private userSvc: UserService,
    private http: HttpClient,
    private confirmationService: ConfirmationService,
    private messageSvc: MessageService,
    private store: StoredBillStoreService,
  ) {}
  async ngOnInit() {
    this.user = this.userSvc.getUser();

    this.isAdmin = this.user.is_organization;
    this.isClient = this.user.is_client;
    this.isProvider = this.user.is_provider;

    this.owner_id = this.user.owner_id;

    await this.load();
  }

  async load(_event?: any) {
    this.loading = true;

    let result = [];

    if (this.isProvider) {
      result = await lastValueFrom(
        this.http.get<any[]>(
          environment.api + 'facturacion/byowner/' + this.owner_id,
        ),
      );
    }

    this.bills = result;
    this.totalRecords = this.bills.length;

    this.loading = false;
  }

  async selectBill(bill: any) {
    this.store.setSelectedBill(bill);
    await this.router.navigate(['account/bills/emitted/detail']);
  }
}
