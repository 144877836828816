import { Injectable } from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormControl,
  FormGroup,
} from '@angular/forms';

@Injectable({
  providedIn: 'root',
})
export class UtilsService {
  public static markGroupControlsAsDirty(group: FormGroup): void {
    UtilsService.markAllControlsAsDirty(Object.values(group.controls));
  }
  public static markArrayControlsAsDirty(array: FormArray): void {
    UtilsService.markAllControlsAsDirty(array.controls);
  }

  public static markAllControlsAsDirty(
    abstractControls: AbstractControl[],
  ): void {
    abstractControls.forEach((abstractControl) => {
      if (abstractControl instanceof FormControl) {
        abstractControl.markAsDirty({ onlySelf: true });
      } else if (abstractControl instanceof FormGroup) {
        this.markAllControlsAsDirty(Object.values(abstractControl.controls));
      } else if (abstractControl instanceof FormArray) {
        this.markAllControlsAsDirty(abstractControl.controls);
      }
    });
  }
  public static htmlFileInputEventToBase64(event: Event): Promise<string> {
    return new Promise((resolve, reject) => {
      const file: Blob | undefined = (event.target as HTMLInputElement)
        .files?.[0];

      if (file) {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result as string);
        reader.onerror = (error) => reject(error);
      } else {
        reject(new Error('No file selected.'));
      }
    });
  }

  public static copyForm(form: FormGroup | FormArray): FormGroup {
    const formCopy = new FormGroup({});
    Object.keys(form.controls).forEach((key) => {
      const control = form.get(key);
      if (control instanceof FormControl) {
        formCopy.addControl(
          key,
          new FormControl(
            control.value,
            control.validator,
            control.asyncValidator,
          ),
        );
      } else if (control instanceof FormGroup) {
        formCopy.addControl(key, UtilsService.copyForm(control));
      } else if (control instanceof FormArray) {
        formCopy.addControl(key, UtilsService.copyForm(control));
      }
    });
    return formCopy;
  }

  public static base64ToBlob(
    b64Data: string,
    contentType = '',
    sliceSize = 512,
  ) {
    b64Data = b64Data.replace(/\s/g, ''); //IE compatibility...

    const byteCharacters = atob(b64Data);
    const byteArrays = [];
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    return new Blob(byteArrays, { type: contentType });
  }

  public static downloadFile(b64encodedString: string, _filename: string) {
    if (b64encodedString) {
      const blob = this.base64ToBlob(b64encodedString, 'text/plain');
      return blob;
    } else {
      return null;
    }
  }
}
