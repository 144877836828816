import { HttpClient } from '@angular/common/http';
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  ConfirmationService,
  FilterMetadata,
  ConfirmEventType,
} from 'primeng/api';
import { Table } from 'primeng/table';
import { lastValueFrom } from 'rxjs';
import {
  User,
  UserService,
  PermissionScope,
  PermissionType,
} from 'src/app/shared/services/user.service';
import { environment } from 'src/environments/environment';
import { Inspection } from 'src/interfaces/inspections';

@Component({
  selector: 'app-retiradas-inspection-list',
  templateUrl: './retiradas-inspection-list.component.html',
  styleUrls: ['./retiradas-inspection-list.component.css'],
})
export class RetiradasInspectionListComponent implements OnInit {
  @ViewChild('dt') table?: Table;

  data?: any[];
  totalRecords?: number;
  loading?: boolean;

  inspections: Inspection[] = [];

  term: string = '';
  fromDate!: Date;
  toDate!: Date;
  page: number = 1;
  limit: number = 10;

  @Input() recordId?: number;

  @Input() geometry?: any = null;

  @Output() onSelected = new EventEmitter<any>();

  requestUrl: string = '';

  user?: User;
  canEdit: boolean = false;
  canDelete: boolean = false;
  canView: boolean = false;
  canViewAll: boolean = false;

  owner_id?: string = '';

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private http: HttpClient,
    private confirmationService: ConfirmationService,
    private userSvc: UserService,
    private detector: ChangeDetectorRef,
  ) {}
  async ngAfterViewInit(): Promise<void> {
    this.detector.detach();
    await this.load();
    this.detector.reattach();
    this.detector.detectChanges();
  }

  ngOnInit() {
    this.user = this.userSvc.getUser();

    (this.user);

    this.canEdit =
      this.user?.hasPermission(
        PermissionScope.WITHDRAWALS,
        PermissionType.EDIT,
      ) || false;
    this.canDelete =
      this.user?.hasPermission(
        PermissionScope.WITHDRAWALS,
        PermissionType.DELETE,
      ) || false;
    this.canView =
      this.user?.hasPermission(
        PermissionScope.WITHDRAWALS,
        PermissionType.VIEW,
      ) || false;
    this.canViewAll =
      this.user?.hasPermission(
        PermissionScope.WITHDRAWALS,
        PermissionType.VIEW_ALL,
      ) || false;

    this.recordId = this.route.snapshot.queryParams['id'];
  }

  async buscarInspecciones() {
    this.loading = true;

    (this.fromDate);
    (this.toDate);

    let date = '';
    if (this.fromDate != null || this.toDate != null) {
      date = `&fromDate=${this.fromDate.valueOf()}&toDate=${this.toDate.valueOf()}`;
    }

    this.requestUrl = `${environment.api}inspection/search?record=${this.recordId}&searchTerm=${this.term}${date != '' ? date : ''}${this.geometry !== null ? '&geometry=' + JSON.stringify(this.geometry.geometry) : ''}`;

    (this.requestUrl);

    const result = await lastValueFrom(this.http.get<any>(this.requestUrl));

    this.data = result?.elements;

    this.data?.forEach(
      (item) =>
        (item.date_inspection = new Date(item.date_inspection).toISOString()),
    );

    (this.data);

    this.totalRecords = result.total;
    this.loading = false;
  }

  async load(event?: any) {
    this.loading = true;
    this.page = event ? event.first / event.rows : 1;
    this.limit = event ? event.rows : 10;

    const _sortField = event
      ? event.sortField
      : this.table?._sortField || undefined;
    const _sortOrder =
      (event
        ? event.sortOrder === 1
          ? 'ASC'
          : 'DESC'
        : this.table?._sortOrder === 1
          ? 'ASC'
          : 'DESC') || 'ASC';
    const _searchTerm =
      event?.globalFilter ||
      (this.table?.filters['global'] as FilterMetadata)?.value ||
      '';

    //const recordId = this.recordId || '';

    this.data = [];
    this.totalRecords = 0;

    if (this.requestUrl != '') {
      this.requestUrl = `${environment.api}retiradas/byrecord/${this.recordId}`; //&page=${this.page - 1}&limit=${this.limit}${ sortField !== undefined ?  '&sortField=' + sortField : '' }&sortOrder=${sortOrder}&searchTerm=${searchTerm}`
    } else {
      this.requestUrl = `${environment.api}retiradas/byrecord/${this.recordId}`; //&page=${this.page - 1}&limit=${this.limit}${ sortField !== undefined ?  '&sortField=' + sortField : '' }&sortOrder=${sortOrder}&searchTerm=${searchTerm}`;
    }

    const result = await lastValueFrom(this.http.get<any>(this.requestUrl));
    this.data = result;
    this.loading = false;
  }
  async selected(data: any) {
    await this.router.navigate(['withdrawals/withdrawal/upsert'], {
      queryParams: {
        recordId: this.recordId,
        agId: data.inspection.agforestId,
        id: data.id,
      },
    });
  }

  async delete(data: any) {
    this.confirmationService.confirm({
      key: 'delete',
      accept: async () => {
        this.loading = true;
        await lastValueFrom(
          this.http.delete(environment.api + 'inspection/' + data?.id),
        );

        await this.load();

        this.loading = false;
      },
      reject: (_type: ConfirmEventType) => {},
    });
  }
}
