import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { SelectItem } from 'primeng/api';
import { environment } from 'src/environments/environment';
import { lastValueFrom } from 'rxjs';
import { User, UserService } from '../../services/user.service';

@Component({
  selector: 'app-user-input',
  templateUrl: './user-input.component.html',
  styleUrls: ['./user-input.component.scss'],
})
export class UserInputComponent implements OnInit {
  users: SelectItem[] = [];

  user?: User;
  isClient: boolean = false;
  isProvider: boolean = false;
  isOrganization: boolean = false;
  owner_id: string = '';

  providerOwnerId: string = '';
  clientInternalId?: number;

  @Input() control?: FormControl;

  loading: boolean = false;
  constructor(
    private readonly http: HttpClient,
    private userSvc: UserService,
  ) {}

  async ngOnInit() {
    this.user = this.userSvc.getUser();

    this.isClient = this.user?.is_client || false;
    this.isProvider = this.user?.is_provider || false;
    this.isOrganization = this.user?.is_organization || false;

    this.owner_id = this.user?.owner_id || '';

    if (this.isClient) {
      const ayto: any = await lastValueFrom(
        this.http.get(environment.api + 'localities/by_owner/' + this.owner_id),
      );
      const record: any = await lastValueFrom(
        this.http.get<unknown>(
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          environment.api + 'expedientes/bylocality/' + ayto.ineInternalId,
        ),
      );

      (record);

      this.providerOwnerId = record.owner_id;
      this.clientInternalId = ayto.ineInternalId;
    }

    await this.loadClients(null);
  }

  async loadClients(event: any) {
    this.loading = true;
    let requestUrl = '';

    if (this.isClient) {
      requestUrl = 'user/by-ownerid/' + this.providerOwnerId;
    }

    if (this.isProvider) {
      requestUrl = 'user/by-ownerid/' + this.userSvc.getUser()?.owner_id;
    }

    if (this.isOrganization) {
      requestUrl = 'user';
    }

    if (requestUrl != '') {
      const d: any = await lastValueFrom(
        this.http.get(environment.api + requestUrl),
      );

      this.users = d.data;

      (this.users);

      this.loading = false;
    }
  }
}
