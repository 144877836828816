import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MapComponent } from 'src/app/shared/components/map/map.component';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { lastValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';
import { UserService } from 'src/app/shared/services/user.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-inspections-create-general',
  templateUrl: './inspections-create-general.component.html',
  styleUrls: ['./inspections-create-general.component.scss'],
})
export class InspectionsCreateGeneralComponent implements OnInit, OnChanges {
  @Input() form?: FormGroup;

  @Input() ine: string = '';

  @Input() newRecord: boolean = false;

  @Input() record: any = null;

  recordId?: number;

  @ViewChild('map') map!: MapComponent;

  @Output() onGeometrySelected: EventEmitter<any> = new EventEmitter<any>();

  loadingMap: boolean = false;

  loaded: boolean = false;

  geometrySelected: any;

  objectKeys = Object.keys;
  locationUseOptions = [
    { value: 'HOUSE', label: 'inspections.locationUseOptions.house' },
    { value: 'OFFICES', label: 'inspections.locationUseOptions.offices' },
    { value: 'COMMERCIAL', label: 'inspections.locationUseOptions.commercial' },
    { value: 'INDUSTRIAL', label: 'inspections.locationUseOptions.industrial' },
    { value: 'PUBLIC_SPACE', label: 'inspections.locationUseOptions.public' },
    {
      value: 'EDUCATIONAL',
      label: 'inspections.locationUseOptions.educational',
    },
    { value: 'HEALTHCARE', label: 'inspections.locationUseOptions.healthcare' },
    {
      value: 'RECREATIONAL',
      label: 'inspections.locationUseOptions.recreational',
    },
    {
      value: 'AGRICULTURAL',
      label: 'inspections.locationUseOptions.agricultural',
    },
    { value: 'WAREHOUSES', label: 'inspections.locationUseOptions.warehouses' },
    { value: 'RELIGIOUS', label: 'inspections.locationUseOptions.religious' },
    { value: 'CULTURAL', label: 'inspections.locationUseOptions.cultural' },
    {
      value: 'GOVERNMENTAL',
      label: 'inspections.locationUseOptions.governmental',
    },
    {
      value: 'TRANSPORTATION',
      label: 'inspections.locationUseOptions.transport',
    },
  ];

  geometries: any[] = [];

  //clients: any[] = [];

  user?: any;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private http: HttpClient,
    private userSvc: UserService,
    private translate: TranslateService,
  ) {}

  async ngOnChanges(changes: SimpleChanges) {
    // si cambia newRecord
    if (changes['newRecord'] && !changes['newRecord'].firstChange) {
      console.log('Execute ngOnChanges');

      await this.getData();
    }
  }

  async ngOnInit() {
    this.recordId = this.route.snapshot.queryParams['recordId'];
    console.log('Execute ngOnInit', this.recordId);

    if (!this.loaded) {
      console.log('Execute ngOnInit');
      await this.getData();
      this.loaded = true;
    }

    this.changeLocationsUseOptions();
  }

  async getData() {
    console.log(this.ine);

    this.user = this.userSvc.getUser();

    this.form?.get('communication_council_date')?.setValue(new Date());
    //this.form?.get('date')?.setValue(new Date(Date.now()));
    this.form
      ?.get('inspector')
      ?.setValue(await this.getUser(this.userSvc.getUser()));

    console.log(this.form);

    //const provider = await lastValueFrom(this.http.get<any>(environment.api + 'owners/provider/clients/' + this.user?.owner_id));
    //this.clients = provider?.clients;

    //this.form?.get('client')?.disable();

    this.form?.get('client')?.setValue(this.record);

    this.recordId = this.route.snapshot.queryParams['recordId'];

    const result = await lastValueFrom(
      this.http.get<any[]>(
        environment.api + 'expedientes/geometries/' + this.recordId,
      ),
    );
    this.geometries = result;

    await this.map.loadGeometry(this.geometries);

    //this.form?.get('date')?.setValue(new Date());

    console.log(this.geometries);
  }

  finishLoading() {
    this.loadingMap = false;
  }

  geometry(e: any) {
    console.log(e);
    this.geometrySelected = e;
    this.onGeometrySelected.emit(e);
  }

  async getUser(usr: any) {
    const result: any = await lastValueFrom(
      this.http.get<any[]>(environment.api + 'user/' + usr.id),
    );

    delete result.client;
    delete result.permissions;
    delete result.records;

    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return result;
  }

  changeLocationsUseOptions() {
    this.locationUseOptions.forEach((option) => {
      option.label = this.translate.instant(option.label);
    });
  }
}
