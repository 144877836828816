import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { User, UserService } from 'src/app/shared/services/user.service';
import { environment } from 'src/environments/environment';
import { lastValueFrom } from 'rxjs';

@Component({
  selector: 'app-account-notifications-permissions',
  templateUrl: './account-notifications.component.html',
  styleUrls: ['./account-notifications.component.scss'],
})
export class AccountNotificationsComponent implements OnInit {
  checked?: boolean;

  user?: User;

  constructor(
    private userService: UserService,
    private http: HttpClient,
    private messageSvc: MessageService,
  ) {}
  ngOnInit(): void {
    this.user = this.userService.getUser();

    this.checked = this.user?.sendNotifications;
  }

  async send() {
    try {
      await lastValueFrom(
        this.http.post(environment.api + 'user/notifications', {
          email: this.userService.getUser()?.email,
          checked: this.checked,
        }),
      );

      this.messageSvc.add({
        severity: 'success',
        summary: 'Exito',
        detail:
          'Se han actualizado sus preferencias. Seran efectivas a partir de su proximo login',
      });
    } catch (e) {
      console.log('Error sending notification');
      return;
    }
  }
}
