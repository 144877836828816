import { HttpClient } from '@angular/common/http';
import { Component, OnInit, signal, WritableSignal } from '@angular/core';
import { Router } from '@angular/router';
import { NotifyFormService } from 'src/app/shared/services/notify-form.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-notify-contact',
  templateUrl: './notify-contact.component.html',
  styleUrls: ['./notify-contact.component.scss'],
})
export class NotifyContactComponent implements OnInit {
  public form = this.formService.form.controls.step_2;

  form1 = this.formService.form.controls.step_1;

  loading = false;
  error = false;

  city!: WritableSignal<string>;

  constructor(
    private formService: NotifyFormService,
    public router: Router,
    private http: HttpClient,
  ) {
    (environment.captcha);
  }
  ngOnInit(): void {
    this.city = signal(this.form1.get('city')?.value || '');
  }

  async validate() {
    if (this.form.valid) {
      this.loading = true;
      await this.send();
      this.loading = false;
    } else {
      this.form.markAllAsTouched();
    }
  }

  async send() {
    const value = {
      ...this.formService.form.value.step_1,
      ...this.formService.form.value.step_2,
    };

    await this.http
      .post(environment.api + 'notify', value)
      .toPromise()
      .then(async (_res) => {
        await this.router.navigateByUrl('/notify/sent');
      })
      .catch((_err) => {
        this.error = true;
      });
  }

  resolved(captchaResponse: string) {
    (`Resolved captcha with response: ${captchaResponse}`);
  }
}
