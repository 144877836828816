import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ConfirmEventType, ConfirmationService } from 'primeng/api';
import { Table } from 'primeng/table';

import { environment } from 'src/environments/environment';
import { lastValueFrom } from 'rxjs';

@Component({
  selector: 'app-admin-list-providers',
  templateUrl: './admin-list-providers.component.html',
  styleUrls: ['./admin-list-providers.component.scss'],
})
export class AdminListProvidersComponent implements OnInit {
  @ViewChild('dt') table?: Table;

  providers: any[] = [];
  totalRecords?: number;
  loading?: boolean;

  _typesFilter: string[] = [];
  set typesFilter(value: string[] | undefined) {
    this._typesFilter = value || [];
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    this.load();
  }
  get typesFilter() {
    return this._typesFilter;
  }

  types?: string[] = [];

  constructor(
    private http: HttpClient,
    private confirmationService: ConfirmationService,
  ) {}
  async ngOnInit() {
    await this.load();
  }

  async load(_event?: any) {
    this.loading = true;

    const result = await lastValueFrom(
      this.http.get<any>(environment.api + 'owners/providers'),
    );

    this.providers = result?.providers;
    this.totalRecords = this.providers.length;

    this.loading = false;
  }


  async deleteProvider(data: any) {
    this.confirmationService.confirm({
      key: 'delete',
      accept: async () => {
        this.loading = true;
        await lastValueFrom(
          this.http.delete(
            environment.api + 'owners/provider/' + data?.owner_id,
          ),
        );

        await this.load();
        this.loading = false;
      },
      reject: (_type: ConfirmEventType) => {},
    });
  }
}
