import { HttpClient } from '@angular/common/http';
import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  ConfirmEventType,
  ConfirmationService,
  MessageService,
} from 'primeng/api';
import { Table } from 'primeng/table';
import { environment } from 'src/environments/environment';
import { lastValueFrom } from 'rxjs';
import { FormControl, FormGroup } from '@angular/forms';
import { UserService } from '../../../shared/services/user.service';

@Component({
  selector: 'app-admin-list-users',
  templateUrl: './admin-list-users.component.html',
  styleUrls: ['./admin-list-users.component.scss'],
})
export class AdminListUsersComponent  {
  @ViewChild('dt') table?: Table;

  @Input() users?: any[];
  @Input() autoload: boolean = false;
  @Input() owner_id?: string;
  @Input() inserting: boolean = false;

  @Input() embed?: boolean;

  user?: any;

  @Output() userDeleted: EventEmitter<any> = new EventEmitter();

  totalRecords?: number;
  loading?: boolean;

  f = new FormGroup({
    user: new FormControl(),
  });

  constructor(
    private http: HttpClient,
    private router: Router,
    private route: ActivatedRoute,
    private userSvc: UserService,
    private confirmationService: ConfirmationService,
    private messageSvc: MessageService,
  ) {}


  async deleteUser(data: any) {
    this.user = data;
    const loggedUser = this.userSvc.getUser();
    if (this.user.id == loggedUser?.id) {
      this.messageSvc.add({
        severity: 'error',
        summary: 'Error',
        detail: 'No puedes borrar tu propio usuario',
      });
      return;
    }

    if (!loggedUser?.is_client) {
      this.confirmationService.confirm({
        key: 'delete',
        accept: async () => {
          this.loading = true;

          try {
            const t = await lastValueFrom(
              this.http.get<any>(environment.api + 'owners/' + data.id),
            );

            if (t.type != 'none') {
              await lastValueFrom(
                this.http.post(
                  environment.api + 'owners/' + t?.type + '/user/' + data.id,
                  this.f.value?.user,
                ),
              );
              await lastValueFrom(
                this.http.delete(
                  environment.api + 'owners/' + t?.type + '/user/' + data.id,
                ),
              );
              ('deleted');
            }

            this.userDeleted.emit();
            this.loading = false;
          } catch (e) {
            this.messageSvc.add({
              severity: 'error',
              summary: 'Error',
              detail:
                'No se puede borrar el usuario al estar referenciado en una Inspeccion',
            });
            this.loading = false;
          }
        },
        reject: (_type: ConfirmEventType) => {},
      });
    } else {
      this.confirmationService.confirm({
        key: 'delete_client_user',
        accept: async () => {
          this.loading = true;

          try {
            const t = await lastValueFrom(
              this.http.delete<any>(environment.api + 'owners/client/user/' + data.id),
            );

            this.userDeleted.emit();
            this.loading = false;
          } catch (e) {
            (e);
            this.messageSvc.add({
              severity: 'error',
              summary: 'Error',
              detail:
                'No se puede borrar el usuario al estar referenciado en una Inspeccion',
            });
            this.loading = false;
          }
        },
        reject: (_type: ConfirmEventType) => {},
      });
    }
  }
}
