
export const environment = {
  production: false,
  api: 'https://dev.amiantic.kn.talentomobile.com/api/',
  captcha: '6LfY758pAAAAAJHlis6mqzJ6eN_RQ0X0mJHalI_G',
  env: 'development',
};



