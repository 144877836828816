import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {

  selectedLanguage = 'es';

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private translator: TranslateService,
  ) {}
  ngOnInit(): void {
    this.selectedLanguage = this.translator.defaultLang;
  }

  async onSelectLocality(event: any) {
    (event);
    await this.router.navigate(['locality', event.value.notify_ine]);
  }

  async onNotifyLocality(event: any) {
    (event);
    await this.router.navigate(['notify', 'location'], {
      queryParams: { ine: event.value.ine },
    });
  }
}
