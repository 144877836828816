import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  AbstractControl,
  FormControl,
  ValidationErrors,
  ValidatorFn,
} from '@angular/forms';
import { AutoCompleteCompleteEvent } from 'primeng/autocomplete';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-locality-input',
  templateUrl: './locality-input.component.html',
  styleUrls: ['./locality-input.component.scss'],
})
export class LocalityInputComponent {
  @Output()
  private onSelect = new EventEmitter<any>();

  _control?: FormControl = new FormControl();
  @Input() set control(control: FormControl) {
    this._control = control;
    this._control?.addValidators(this.validator());
    this._control?.updateValueAndValidity();
  }

  public filteredLocalities: string[] = [];
  public localities: string[] = [];

  constructor(private http: HttpClient) {
    this.http
      .get<string[]>(environment.api + 'localities/')
      .subscribe((localities) => {
        this.localities = localities;
      });
  }

  filterTowns(event: AutoCompleteCompleteEvent) {
    const filtered: any[] = [];
    const query = event.query;

    for (let i = 0; i < this.localities.length; i++) {
      const locality = this.localities[i] as any;
      const name = locality.name;
      if (
        name &&
        name
          .toLowerCase()
          .indexOf(
            query.toLowerCase(),
          ) == 0
      ) {
        filtered.push(locality);
      }
    }
    this.filteredLocalities = filtered;
  }
  _onSelect(event: any) {
    (this.control?.value);
    this.onSelect.emit(event);
  }

  validator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;
      return value?.ineInternalId ? null : { locality: true };
    };
  }
}
