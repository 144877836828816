import { HttpClient } from '@angular/common/http';
import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import {
  ConfirmationService,
  ConfirmEventType,
  MessageService,
} from 'primeng/api';
import { Observable, lastValueFrom } from 'rxjs';
import { UtilsService } from 'src/app/shared/services/utils.service';
import { environment } from 'src/environments/environment';
import { Location } from '@angular/common';
import { UserService } from 'src/app/shared/services/user.service';

@Component({
  selector: 'app-provider-clients-upsert-providers',
  templateUrl: './provider-clients-upsert-providers.component.html',
  styleUrls: ['./provider-clients-upsert-providers.component.scss'],
})
export class ProviderClientsUpsertProvidersComponent implements OnInit {
  @Input() filtered: boolean = false;

  client?: any;
  loading?: boolean;
  deleteLoading?: boolean;
  types?: string[];
  form = new FormGroup({
    owner_id: new FormControl(),
    // eslint-disable-next-line @typescript-eslint/unbound-method
    cif: new FormControl(null, Validators.required),
    // eslint-disable-next-line @typescript-eslint/unbound-method
    businessName: new FormControl(null, Validators.required),
    // eslint-disable-next-line @typescript-eslint/unbound-method
    email: new FormControl(null, [Validators.required, Validators.email]),
    phone: new FormControl(null),
    address: new FormControl(null),
  });

  errors: { [key: string]: string | boolean } = {};

  users: any[] = [];

  @Input() providerOwnerId?: string;

  owner_id?: string;
  id?: number;

  inserting: boolean = false;

  providers: any = { providers: [] };
  providerName: string = '';

  selectedProvider: any = null;

  user?: any;
  isAdmin: boolean = false;

  constructor(
    private readonly http: HttpClient,
    private confirmationService: ConfirmationService,
    private router: Router,
    private route: ActivatedRoute,
    private detector: ChangeDetectorRef,
    private toastService: MessageService,
    private location: Location,
    private userSVC: UserService,
  ) {}
  async ngOnInit() {
    this.user = this.userSVC.getUser();
    this.isAdmin = this.user.is_organization;

    const id = this.route.snapshot.queryParams['id'];

    this.owner_id = this.route.snapshot.queryParams['owner_id'] || null;

    if (id) {
      this.id = id;
      this.inserting = false;
      await this.loadProviders(id);
    } else {
      this.inserting = true;
      this.providers = await lastValueFrom(
        this.http.get<any>(environment.api + 'owners/providers'),
      );
    }
  }

  async loadProviders(_id: string) {
    this.loading = true;

    const client = await lastValueFrom(
      this.http.get<any>(
        environment.api + `owners/provider/clients/${this.owner_id}/${this.id}`,
      ),
    );

    const result = await lastValueFrom(
      this.http.get<{ providers: any[] }>(environment.api + 'owners/providers'),
    );

    this.providers = result.providers;

    this.selectedProvider = this.providers.find(
      (provider: any) => provider.owner_id == this.owner_id,
    );

    this.client = client;
    this.inserting = false;

    if (this.selectedProvider.owner_id) {
      this.owner_id = this.selectedProvider.owner_id;
    }

    this.form.patchValue({
      owner_id: this.owner_id,
      cif: client.cif,
      businessName: client.businessName,
      email: client.email,
      phone: client.phone,
      address: client.address,
    });

    this.loading = false;
  }

  selectProvider(event: any) {
    this.selectedProvider = event.value;
  }

  async save() {
    if (this.form.valid) {
      this.loading = true;

      if (this.selectedProvider?.owner_id) {
        this.owner_id = this.selectedProvider.owner_id;
      }

      this.form.get('owner_id')?.setValue(this.owner_id);

      const values: any = this.form.value;

      const request: Observable<any> = !this.inserting
        ? this.http.put(
            environment.api +
              `owners/provider/clients/${this.owner_id}/${this.id}`,
            values,
          )
        : this.http.post(environment.api + 'owners/provider/clients', values);

      await request
        .toPromise()
        .then((_data: any) => {
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          this.router.navigate(['../'], { relativeTo: this.route });
        })
        .catch((e) => {
          if (e.error.message === 'EMAIL_ALREADY_EXIST') {
            this.form.get('email')?.setErrors({ emailAlreadyExist: true });
            this.toastService.add({
              severity: 'error',
              summary: 'Error',
              detail: 'El correo ya existe',
            });
            UtilsService.markGroupControlsAsDirty(this.form);
          }
        });

      this.loading = false;
    } else {
      UtilsService.markGroupControlsAsDirty(this.form);
    }
  }

  delete() {
    this.confirmationService.confirm({
      accept: async () => {
        this.deleteLoading = true;
        await this.http
          .delete(environment.api + 'owners/provider/' + this.client.id)
          .toPromise()
          .then((_data: any) => {
            // eslint-disable-next-line @typescript-eslint/no-floating-promises
            this.router.navigate(['../'], { relativeTo: this.route });
          })
          .catch((error) => {
            (error);
          });
        this.deleteLoading = false;
      },
      reject: (_type: ConfirmEventType) => {},
    });
  }

  goBack() {
    this.location.back();
  }
}
