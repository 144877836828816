<div class="mb-8" style="padding: 100px; background: #f0f0f0; text-align: center;">
  <div class="relative">
    <div class="absolute z-10 w-full h-full flex items-center justify-center bg-opacity-50 bg-gray-700"  *ngIf="loadingMap">
      <p-progressSpinner styleClass="custom-spinner"></p-progressSpinner>
    </div>
    <div class="relative z-0">
      <app-map #map [ine]="ine" [loadFromUrl]="false" [data]="geometries"
      [enable_address_bar]="true"
        [draw_enabled_on_startup]="true"
        [enable_draw]="false"
        [is_geolocation_enabled]="true"
        [get_coordinates_on_click]="false"
        [show_on_click]="true"
        class="flex h-[600px]" (onDrawGeometry)="geometry($event)" (onFinishLoading)="finishLoading()"></app-map>
    </div>
  </div>
</div>
<div>
  <div class=" flex flex-wrap items-start gap-10 columns-3">
    <div class=" flex flex-col w-1/4 p-input-underline">
      <label for="num_inspection" class="font-medium text-sm text-am-secondary-navy-60 ">{{ 'inspections.recordNum' | translate}}</label>
      <input pInputText id="recordNumber" type="number" [placeholder]="'Ej. 1234567890123456789'" [formControl]="form?.get('inspectionNumber')" maxlength="16">
    </div>
      <div class=" flex flex-col w-1/4 p-input-underline">
          <label for="inspector" class="font-medium text-sm text-am-secondary-navy-60 ">{{ 'inspections.inspector' | translate}}</label>
          <app-user-input [control]="form?.get('inspector')"></app-user-input>
      </div>
      <div class=" flex flex-col w-1/4 ml-8">
        <label for="date" class="font-medium text-sm text-am-secondary-navy-60 ">{{ 'inspections.inspectionDate1' | translate}}</label>
        <p-calendar [showTime]="true" [showIcon]="true" [placeholder]="'Ej. 2021-01-01'" dateFormat="dd/mm/yy"  [formControl]="form?.get('date')"></p-calendar>
      </div>
      <div class=" flex flex-col w-1/4">
        <label for="communication_council_date" class="font-medium text-sm text-am-secondary-navy-60 ">{{ 'inspections.communicationDate' | translate}}</label>
        <p-calendar [showIcon]="true" [placeholder]="'Ej. 2021-01-01'" dateFormat="dd/mm/yy" [formControl]="form?.get('communication_council_date')"></p-calendar>
      </div>
      <div class=" flex flex-col w-[100%]">
          <label for="relevantInformation" class="font-medium text-sm text-am-secondary-navy-60 ">{{ 'inspections.relevantInfo' | translate}}</label>
          <textarea pInputTextarea id="relevantInformation" type="text" [placeholder]="'inspections.relevantInfoPlaceholder' | translate" [formControl]="form?.get('relevantInformation')"></textarea>
      </div>

      <app-localizaciones class="w-screen" [extended]="true" [form]="form"></app-localizaciones>
  </div>
</div>
