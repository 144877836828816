import { HttpClient } from '@angular/common/http';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { lastValueFrom } from 'rxjs';
import { MapComponent } from 'src/app/shared/components/map/map.component';
import {
  PermissionScope,
  PermissionType,
  User,
  UserService,
} from 'src/app/shared/services/user.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-inspections-explore-map',
  templateUrl: './inspections-explore-map.component.html',
  styleUrls: ['./inspections-explore-map.component.scss'],
})
export class InspectionsExploreMapComponent implements OnInit, AfterViewInit {
  loadingMap: boolean = false;
  geometrySelected: any;

  geometries: any[] = [];

  @ViewChild('map') map!: MapComponent;

  user?: User;
  canCreate: boolean = false;

  owner_id?: string = '';
  record: any;
  recordId: any;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private http: HttpClient,
    private userSvc: UserService,
    private detector: ChangeDetectorRef,
  ) {}

  async ngAfterViewInit() {
    await this.map?.loadGeometry(this.geometries);
  }

  async ngOnInit() {
    this.detector.detach();
    this.user = this.userSvc.getUser();

    this.canCreate =
      this.user?.hasPermission(
        PermissionScope.INSPECTIONS,
        PermissionType.CREATE,
      ) || false;

    this.owner_id = this.user?.owner_id;

    if (this.owner_id == '') {
      this.canCreate = false;
    }

    this.recordId = this.route.snapshot.queryParams['record'];

    const result = await lastValueFrom(
      this.http.get<any[]>(
        environment.api + 'expedientes/geometries/' + this.recordId,
      ),
    );

    const data = await lastValueFrom(
      this.http.get(environment.api + 'expedientes/byid/ro/' + this.recordId),
    );

    this.record = data;

    this.canCreate = this.canCreate ? this.record.status == 'ACTIVE' : false;
    this.geometries = result;

    this.detector.reattach();
    this.detector.detectChanges();
  }

  async selected(data: any) {
    (data);
    await this.router.navigate(['inspection'], {
      queryParams: { recordId: this.recordId, id: data.agForestId },
    });
  }

  finishLoading() {
    this.loadingMap = false;
  }

  geometry(e: any) {
    this.geometrySelected = e;
  }

  async addInspection() {
    await this.router.navigate(['/inspections/create'], {
      queryParams: { recordId: this.recordId, ine: this.record.locality.ine },
    });
  }
}
