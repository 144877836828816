import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { ConfirmationService } from 'primeng/api';
import { MaterialList } from './materiales.data';
import { AutoCompleteCompleteEvent } from 'primeng/autocomplete';
import { PopupfichainspComponent } from '../popupfichainsp/popupfichainsp.component';
import {
  PermissionScope,
  PermissionType,
  User,
  UserService,
} from '../../services/user.service';
import { TranslateService } from '@ngx-translate/core';

export const mcaOptionsES = [
  { id: 'YES', name: 'Si' },
  { id: 'NO', name: 'No' },
];

export const mcaOptionsEN = [
  { id: 'YES', name: 'Yes' },
  { id: 'NO', name: 'No' },
];
export const mcaManagementOptions = ['MCA', 'MPCA', 'NOMCA'];

export const AsbestosUnit: { [key: string]: string } = {
  m: 'm',
  m2: 'm²',
  m3: 'm³',
  uds: 'unidades',
};

@Component({
  selector: 'app-localizaciones',
  templateUrl: './localizaciones.component.html',
  styleUrls: ['./localizaciones.component.scss'],
})
export class LocalizacionesComponent implements OnInit {
  @Input() form!: FormGroup;
  @Input() locations: any[] = [];
  @Input() extended: boolean = true;

  visible: boolean = false;

  @ViewChild('cd') cd!: ConfirmationService;

  strategyOptions = [
    { value: 'VISUAL', label: 'inspections.strategyOptions.visual' },
    { value: 'MUESTRA', label: 'inspections.strategyOptions.sample' },
    {
      value: 'CRITERIO TÉCNICO',
      label: 'inspections.strategyOptions.technicalCriterion',
    },
  ];

  mcaOptionsES = [
    { id: 'YES', name: 'Si' },
    { id: 'NO', name: 'No' },
  ];

  mcaOptionsEN = [
    { id: 'YES', name: 'Yes' },
    { id: 'NO', name: 'No' },
  ];
  mcaManagementOptions = mcaManagementOptions;
  asbestosUnit = AsbestosUnit;
  objectKeys = Object.keys;

  materials: string[] = MaterialList;
  suggestions: string[] = [];

  fichaIndex = -1;
  selectedForm?: FormGroup;

  user?: User;
  canEdit: boolean = false;
  canDelete: boolean = false;
  canView: boolean = false;
  canViewAll: boolean = false;
  isClient: boolean = false;
  isProvider: boolean = false;
  isOrganization: boolean = false;

  owner_id: string = '';
  canCreateReport: boolean = false;

  editingForm!: FormGroup;

  selectedLanguage: string = 'es';

  @ViewChild('popup') popup!: PopupfichainspComponent;
  @ViewChild('addLoc') addLoc!: ElementRef;

  constructor(
    private confirmationService: ConfirmationService,
    private detector: ChangeDetectorRef,
    private userSvc: UserService,
    private translate: TranslateService,
  ) {}

  private clientPermissions() {
    this.canEdit = false;
    this.canDelete = false;
    this.canView = true;
    this.canViewAll = true;
  }

  private providerPermissions() {
    this.canEdit =
      this.user?.hasPermission(
        PermissionScope.INSPECTIONS,
        PermissionType.EDIT,
      ) || false;
    this.canDelete =
      this.user?.hasPermission(
        PermissionScope.INSPECTIONS,
        PermissionType.DELETE,
      ) || false;
    this.canView =
      this.user?.hasPermission(
        PermissionScope.INSPECTIONS,
        PermissionType.VIEW,
      ) || false;
    this.canViewAll =
      this.user?.hasPermission(
        PermissionScope.INSPECTIONS,
        PermissionType.VIEW_ALL,
      ) || false;
    this.canCreateReport =
      this.user?.hasPermission(
        PermissionScope.INSPECTION_INFORM,
        PermissionType.CREATE,
      ) ||
      this.user?.hasPermission(
        PermissionScope.INSPECTION_INFORM,
        PermissionType.EDIT,
      ) ||
      false;
  }

  private organizationPermissions() {
    this.canEdit = false;
    this.canDelete = false;
    this.canView = false;
    this.canViewAll = false;
  }

  ngOnInit(): void {
    this.changeStrategyOptionsLabel();
    this.user = this.userSvc.getUser();

    this.selectedLanguage = this.translate.defaultLang;

    this.isClient = this.user?.is_client || false;
    this.isProvider = this.user?.is_provider || false;
    this.isOrganization = this.user?.is_organization || false;

    if (this.isClient) {
      this.clientPermissions();
    }

    if (this.isProvider) {
      this.providerPermissions();
      this.owner_id = this.user?.owner_id || '';
    }

    if (this.isOrganization) {
      this.organizationPermissions();
    }
  }

  imageAdded(e: any, index: number) {
    const pictures = (this.form.get('locations') as FormArray)
      ?.at(index)
      .get('picturesToAdd')?.value;
    pictures.push(e);

    (this.form.get('locations') as FormArray)
      ?.at(index)
      .get('picturesToAdd')
      ?.patchValue(pictures);
  }

  imageRemoved(e: any, index: number) {
    // Puede ser que este recien añadida
    const pictures = (this.form.get('locations') as FormArray)
      ?.at(index)
      .get('picturesToAdd')?.value;
    const result = pictures.filter((item: any) => item == e);

    if (result.length > 0) {
      // en cuyo caso la sacamos de la lista de añadidas
      const result = pictures.filter((item: any) => item != e);
      (this.form.get('locations') as FormArray)
        ?.at(index)
        .get('picturesToAdd')
        ?.patchValue(result);
    } else {
      // o que ya estuviera previamente añadida
      const pictures2 = (this.form.get('locations') as FormArray)
        ?.at(index)
        .get('pictures')?.value;
      const result2 = [
        ...result,
        pictures.filter((item: any) => item.path == e.path),
      ];

      if (result2.length > 0) {
        // en cuyo caso la insertamos en la lista de las de borrar
        const result = pictures2.filter((item: any) => item.path != e.path);

        (result);

        (this.form.get('locations') as FormArray)
          ?.at(index)
          .get('pictures')
          ?.patchValue(result);

        const result3 = (this.form.get('locations') as FormArray)
          ?.at(index)
          .get('picturesToDelete')?.value;
        result3.push(e);

        (this.form.get('locations') as FormArray)
          ?.at(index)
          .get('picturesToDelete')
          ?.patchValue(result3);
      }
    }

    ((this.form.get('locations') as FormArray)?.at(index));
  }

  schemaAdded(e: any, index: number) {
    const pictures = (this.form.get('locations') as FormArray)
      ?.at(index)
      .get('picturesSchemasToAdd')?.value;
    pictures.push(e);

    (this.form.get('locations') as FormArray)
      ?.at(index)
      .get('picturesSchemasToAdd')
      ?.patchValue(pictures);
  }

  schemaRemoved(e: any, index: number) {
    // Puede ser que este recien añadida
    const pictures = (this.form.get('locations') as FormArray)
      ?.at(index)
      .get('picturesSchemasToAdd')?.value;
    const result = pictures.filter((item: any) => item == e);

    if (result.length > 0) {
      // en cuyo caso la sacamos de la lista de añadidas
      const result = pictures.filter((item: any) => item != e);
      (this.form.get('locations') as FormArray)
        ?.at(index)
        .get('picturesSchemasToAdd')
        ?.patchValue(result);
    } else {
      // o que ya estuviera previamente añadida
      const pictures2 = (this.form.get('locations') as FormArray)
        ?.at(index)
        .get('picturesSchemas')?.value;
      const result2 = [
        ...result,
        pictures.filter((item: any) => item.path == e.path),
      ];

      if (result2.length > 0) {
        // en cuyo caso la insertamos en la lista de las de borrar
        const result = pictures2.filter((item: any) => item.path != e.path);
        (this.form.get('locations') as FormArray)
          ?.at(index)
          .get('picturesSchemas')
          ?.patchValue(result);

        const result3 = (this.form.get('locations') as FormArray)
          ?.at(index)
          .get('picturesSchemasToDelete')?.value;
        result3.push(e);

        (this.form.get('locations') as FormArray)
          ?.at(index)
          .get('picturesSchemasToDelete')
          ?.patchValue(result3);
      }
    }

    ((this.form.get('locations') as FormArray)?.at(index));
  }

  labAdded(e: any, index: number) {
    const pictures = (this.form.get('locations') as FormArray)
      ?.at(index)
      .get('picturesLabToAdd')?.value;
    pictures.push(e);

    (this.form.get('locations') as FormArray)
      ?.at(index)
      .get('picturesLabToAdd')
      ?.patchValue(pictures);
  }

  labRemoved(e: any, index: number) {
    // Puede ser que este recien añadida
    const pictures = (this.form.get('locations') as FormArray)
      ?.at(index)
      .get('picturesLabToAdd')?.value;
    const result = pictures.filter((item: any) => item.filename == e.filename);

    if (result.length > 0) {
      // en cuyo caso la sacamos de la lista de añadidas
      const result = pictures.filter(
        (item: any) => item.filename != e.filename,
      );
      (this.form.get('locations') as FormArray)
        ?.at(index)
        .get('picturesLabToAdd')
        ?.patchValue(result);
    } else {
      // o que ya estuviera previamente añadida
      const pictures2 = (this.form.get('locations') as FormArray)
        ?.at(index)
        .get('picturesLab')?.value;

      const result2 = [
        ...result,
        pictures2.filter((item: any) => item.path == e.path),
      ];

      if (result2.length > 0) {
        // en cuyo caso la insertamos en la lista de las de borrar
        const result = pictures2.filter((item: any) => item.path != e.path);

        (this.form.get('locations') as FormArray)
          ?.at(index)
          .get('picturesLab')
          ?.patchValue(result);

        const result3 = (this.form.get('locations') as FormArray)
          ?.at(index)
          .get('picturesLabToDelete')?.value;
        result3.push(e);

        (this.form.get('locations') as FormArray)
          ?.at(index)
          .get('picturesLabToDelete')
          ?.patchValue(result3);
      }
    }
  }

  openReport(f: FormGroup) {
    this.detector.detach();

    this.editingForm = f;
    this.visible = true;

    this.detector.reattach();

    this.detector.detectChanges();
  }

  search(event: AutoCompleteCompleteEvent) {
    this.suggestions = this.materials.filter((item) =>
      item
        .toLowerCase()
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .toLocaleLowerCase('es-ES')
        .includes(
          event.query
            .toLowerCase()
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
            .toLocaleLowerCase('es-ES'),
        ),
    );
  }

  addLocation() {
    (this.form.get('locations') as FormArray).insert(
      0,
      new FormGroup({
        id: new FormControl(),
        // eslint-disable-next-line @typescript-eslint/unbound-method
        name: new FormControl('', [Validators.required]),
        samplingStrategy: new FormControl(),
        management: new FormControl(),
        material: new FormControl(),
        mca: new FormControl(),
        sample_withdrawal_date: new FormControl(),

        pictures: new FormControl<
          { filename: string; url: string; data: string }[]
        >([]),
        picturesLab: new FormControl<
          { filename: string; url: string; data: string }[]
        >([]),
        picturesSchemas: new FormControl<
          { filename: string; url: string; data: string }[]
        >([]),

        picturesToAdd: new FormControl<
          { filename: string; url: string; data: string }[]
        >([]),
        picturesLabToAdd: new FormControl<
          { filename: string; url: string; data: string }[]
        >([]),
        picturesSchemasToAdd: new FormControl<
          { filename: string; url: string; data: string }[]
        >([]),

        picturesToDelete: new FormControl<
          { filename: string; url: string; data: string }[]
        >([]),
        picturesLabToDelete: new FormControl<
          { filename: string; url: string; data: string }[]
        >([]),
        picturesSchemasToDelete: new FormControl<
          { filename: string; url: string; data: string }[]
        >([]),

        risk_location: new FormControl(),
        risk_level_location: new FormControl(),
        risk_quantity: new FormControl(),
        risk_level_quantity: new FormControl(),
        risk_accessibility: new FormControl(),
        risk_level_accessibility: new FormControl(),
        risk_technical_accesibility: new FormControl(),
        risk_level_technical_accesibility: new FormControl(),
        risk_material_status: new FormControl(),
        risk_level_material_status: new FormControl(),
        risk_variery: new FormControl(),
        risk_level_variery: new FormControl(),
        risk_fiability: new FormControl(),
        risk_level_fiability: new FormControl(),
        risk_number_exposed: new FormControl(),
        risk_level_number_exposed: new FormControl(),

        asbestos_quantity: new FormControl(),
        asbestos_quantity_unit: new FormControl(),
        asbestos_quantity_description: new FormControl(),
      }),
    );
    this.detector.detectChanges();
  }
  removeLocation(index: number) {
    (this.form.get('locations') as FormArray).removeAt(index);
  }

  onClosePopup() {
    this.visible = false;
  }

  onSaveReport(e: any[]) {
    for (const q of e) {
      this.editingForm.get(q.key)?.patchValue(q.response);

      const [_risk, wich, other] = q.key.split('_');
      this.editingForm
        .get(`risk_level_${wich}${other != undefined ? '_' + other : ''}`)
        ?.patchValue(q.score);
    }

    this.editingForm.get('inspection_data')?.patchValue(e);
    this.visible = false;
  }

  changeStrategyOptionsLabel() {
    this.strategyOptions.forEach((item) => {
      item.label = this.translate.instant(item.label);
    });
  }
}
