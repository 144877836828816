import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService, MessageService } from 'primeng/api';
import { Table } from 'primeng/table';
import { lastValueFrom } from 'rxjs';
import { UserService } from 'src/app/shared/services/user.service';
import { environment } from 'src/environments/environment';
import { CurrentContractStoreService } from './current-store.service';

@Component({
  selector: 'app-current-contracts',
  templateUrl: './current-contracts.component.html',
  styleUrls: ['./current-contracts.component.css'],
})
export class CurrentContractsComponent implements OnInit {
  @ViewChild('dt') table?: Table;

  contracts?: any[] = [];
  totalRecords?: number;
  loading?: boolean;

  user?: any;

  isAdmin: boolean = false;
  isClient: boolean = false;
  isProvider: boolean = false;

  owner_id?: string;

  _typesFilter: string[] = [];
  set typesFilter(value: string[] | undefined) {
    this._typesFilter = value || [];
    this.load();
  }
  get typesFilter() {
    return this._typesFilter;
  }

  types?: string[] = [];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private userSvc: UserService,
    private http: HttpClient,
    private confirmationService: ConfirmationService,
    private messageSvc: MessageService,
    private store: CurrentContractStoreService,
  ) {}
  ngOnInit(): void {
    this.user = this.userSvc.getUser();

    this.isAdmin = this.user.is_organization;
    this.isClient = this.user.is_client;
    this.isProvider = this.user.is_provider;

    this.owner_id = this.user.owner_id;

    this.load();
  }

  async load(event?: any) {
    this.loading = true;

    let result = [];

    if (this.isProvider) {
      result = await lastValueFrom(
        this.http.get<any>(
          environment.api + 'contracts/current/' + this.owner_id,
        ),
      );
    }

    if (this.isAdmin) {
    }

    this.contracts = result as any[];
    this.totalRecords = this.contracts.length;

    this.loading = false;
  }

  selectContract(contract: any) {
    this.store.setSelectedContract(contract);

    if (contract.typeOfContract == 0) {
      this.router.navigate(['account/current-contracts/edit-linked']);
    } else {
      this.router.navigate(['account/current-contracts/edit-independent']);
    }
  }
}
