import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  ConfirmEventType,
  ConfirmationService,
  FilterMetadata,
  MessageService,
} from 'primeng/api';
import { Table } from 'primeng/table';
import { environment } from 'src/environments/environment';
import { lastValueFrom } from 'rxjs';
import { FormControl, FormGroup } from '@angular/forms';
import { UserService } from '../../../shared/services/user.service';

@Component({
  selector: 'app-organization-list-users',
  templateUrl: './organization-list-users.component.html',
  styleUrls: ['./organization-list-users.component.scss'],
})
export class OrganizationListUsersComponent implements OnInit {
  @ViewChild('dt') table?: Table;

  users: any[] = [];
  user?: any;

  totalRecords?: number;
  loading?: boolean;

  f = new FormGroup({
    user: new FormControl(null),
  });

  constructor(
    private http: HttpClient,
    private router: Router,
    private route: ActivatedRoute,
    private userSvc: UserService,
    private confirmationService: ConfirmationService,
    private messageSvc: MessageService,
  ) {}

  async ngOnInit() {
    await this.load();
  }

  async load(event?: any) {
    this.loading = true;
    const page = event ? event.first / event.rows : 0;
    const limit = event ? event.rows : 10;
    const sortField = event ? event.sortField : this.table?._sortField || '';
    const sortOrder =
      (event
        ? event.sortOrder === 1
          ? 'ASC'
          : 'DESC'
        : this.table?._sortOrder === 1
          ? 'ASC'
          : 'DESC') || 'ASC';
    const searchTerm =
      event?.globalFilter ||
      (this.table?.filters['global'] as FilterMetadata)?.value ||
      '';

    const requestUrl = `${environment.api}organization/users?page=${page}&limit=${limit}&sortField=${sortField}&sortOrder=${sortOrder}&searchTerm=${searchTerm}`;
    await this.http
      .get(requestUrl)
      .toPromise()
      .then((data: any) => {
        this.users = data.data;
        this.totalRecords = data.total;
        this.loading = false;
      })
      .catch((e) => {
        (e);
        this.users = [];
        this.totalRecords = 0;
      });
    this.loading = false;
  }

  async deleteUser(data: any) {
    this.user = data;

    const loggedUser = this.userSvc.getUser();

    if (this.user.id == loggedUser?.id) {
      this.messageSvc.add({
        severity: 'error',
        summary: 'Error',
        detail: 'No puedes borrar tu propio usuario',
      });
      return;
    }

    this.confirmationService.confirm({
      key: 'delete',
      accept: async () => {
        try {
          this.loading = true;

          await lastValueFrom(
            this.http.delete(environment.api + 'organization/users/' + data.id),
          );

          await this.load();
          this.loading = false;
        } catch (e) {
          (e);
          this.messageSvc.add({
            severity: 'error',
            summary: 'Error',
            detail:
              'No se puede borrar el usuario al estar referenciado en una Inspeccion',
          });
          this.loading = false;
        }
      },
      reject: (_type: ConfirmEventType) => {},
    });
  }
}
