import { HttpClient } from '@angular/common/http';
import {
  Component,
  ElementRef,
  ViewChild,
  OnInit,
  AfterViewInit,
  ChangeDetectorRef,
} from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import {
  ConfirmEventType,
  ConfirmationService,
  MessageService,
} from 'primeng/api';
import { lastValueFrom } from 'rxjs';
import { UtilsService } from 'src/app/shared/services/utils.service';
import { environment } from 'src/environments/environment';
import { Location } from '@angular/common';
import { PdfGeneratorService } from 'src/app/shared/services/pdfgenerator.service';
import { MapComponent } from 'src/app/shared/components/map/map.component';
import {
  PermissionScope,
  PermissionType,
  User,
  UserService,
} from 'src/app/shared/services/user.service';
import { TranslateService } from '@ngx-translate/core';

// export const locationUseOptions: { label: string; value: string }[] = [
//   { value: 'HOUSE', label: 'inspections.locationUseOptions.house' },
//   { value: 'OFFICES', label: 'inspections.locationUseOptions.offices' },
//   { value: 'COMMERCIAL', label: 'inspections.locationUseOptions.commercial' },
//   { value: 'INDUSTRIAL', label: 'inspections.locationUseOptions.industrial' },
//   { value: 'PUBLIC_SPACE', label: 'inspections.locationUseOptions.public' },
//   { value: 'EDUCATIONAL', label: 'inspections.locationUseOptions.educational' },
//   { value: 'HEALTHCARE', label: 'inspections.locationUseOptions.healthcare' },
//   {
//     value: 'RECREATIONAL',
//     label: 'inspections.locationUseOptions.recreational',
//   },
//   {
//     value: 'AGRICULTURAL',
//     label: 'inspections.locationUseOptions.agricultural',
//   },
//   { value: 'WAREHOUSES', label: 'inspections.locationUseOptions.warehouses' },
//   { value: 'RELIGIOUS', label: 'inspections.locationUseOptions.religious' },
//   { value: 'CULTURAL', label: 'inspections.locationUseOptions.cultural' },
//   {
//     value: 'GOVERNMENTAL',
//     label: 'inspections.locationUseOptions.governmental',
//   },
//   {
//     value: 'TRANSPORTATION',
//     label: 'inspections.locationUseOptions.transport',
//   },
// ];

@Component({
  selector: 'app-inspection-edit',
  templateUrl: './inspection-edit.component.html',
  styleUrls: ['./inspection-edit.component.scss'],
})
export class InspectionEditComponent implements OnInit, AfterViewInit {
  @ViewChild('pdfContent', {
    static: false,
  })
  pdfContent!: ElementRef;

  objectKeys = Object.keys;

  locationUseOptions = [
    { value: 'HOUSE', label: 'inspections.locationUseOptions.house' },
    { value: 'OFFICES', label: 'inspections.locationUseOptions.offices' },
    { value: 'COMMERCIAL', label: 'inspections.locationUseOptions.commercial' },
    { value: 'INDUSTRIAL', label: 'inspections.locationUseOptions.industrial' },
    { value: 'PUBLIC_SPACE', label: 'inspections.locationUseOptions.public' },
    {
      value: 'EDUCATIONAL',
      label: 'inspections.locationUseOptions.educational',
    },
    { value: 'HEALTHCARE', label: 'inspections.locationUseOptions.healthcare' },
    {
      value: 'RECREATIONAL',
      label: 'inspections.locationUseOptions.recreational',
    },
    {
      value: 'AGRICULTURAL',
      label: 'inspections.locationUseOptions.agricultural',
    },
    { value: 'WAREHOUSES', label: 'inspections.locationUseOptions.warehouses' },
    { value: 'RELIGIOUS', label: 'inspections.locationUseOptions.religious' },
    { value: 'CULTURAL', label: 'inspections.locationUseOptions.cultural' },
    {
      value: 'GOVERNMENTAL',
      label: 'inspections.locationUseOptions.governmental',
    },
    {
      value: 'TRANSPORTATION',
      label: 'inspections.locationUseOptions.transport',
    },
  ];
  deleteWasteOptions = [
    { value: 'INTERNAL', label: 'inspections.deleteWasteOptions.internal' },
    { value: 'EXTERNAL', label: 'inspections.deleteWasteOptions.external' },
  ];

  mcaOptions = [
    { value: 'YES', label: 'inspections.mcaOptions.yes' },
    { value: 'NO', label: 'inspections.mcaOptions.no' },
  ];
  mcaManagementOptions = [
    { value: 'MCA', label: 'inspections.mcaManagementOptions.mca' },
    { value: 'MPCA', label: 'inspections.mcaManagementOptions.mpca' },
    { value: 'NOMCA', label: 'inspections.mcaManagementOptions.nomca' },
  ];

  inspectionTypeOptions = [
    { value: 'TIPO 1', label: 'inspections.inspectionTypeOptions.type1' },
    { value: 'TIPO 2', label: 'inspections.inspectionTypeOptions.type2' },
  ];

  loading: boolean = false;
  deleteLoading: boolean = false;

  pictures: any[] = [];

  data: any = null;

  errors: { [key: string]: string | boolean } = {};

  loadingMap: boolean = false;
  geometrySelected: any;
  @ViewChild('map', { static: false }) map!: MapComponent;

  user?: User;
  canCreate: boolean = false;
  canEdit: boolean = false;
  canDelete: boolean = false;
  canView: boolean = false;
  canViewAll: boolean = false;
  isClient: boolean = false;
  isProvider: boolean = false;
  isOrganization: boolean = false;

  status: string = '';

  providerOwnerId: string = '';
  clientInternalId?: number;

  generateReportMenu = [
    {
      label: this.translate.instant('admin.generatePDF'),
      icon: 'pi pi-fw pi-file-pdf',
      command: async () => {
        await this.generateReport();
      },
    },
    {
      label: this.translate.instant('admin.generateWord'),
      icon: 'pi pi-fw pi-file-word',
      command: async () => {
        await this.downloadTemplate();
      },
    },
  ];

  form = new FormGroup({
    id: new FormControl(),

    //client: new FormControl({disabled: true}),
    recordNumber: new FormControl({ disabled: true }),
    owner_id: new FormControl(),
    locality: new FormControl(),
    address: new FormControl(),
    year: new FormControl(),
    catastralReference: new FormControl(),
    locationUse: new FormControl(),
    buildingOwner: new FormControl(),
    inspectionNumber: new FormControl(),
    inspectionType: new FormControl(),
    inspectionObjective: new FormControl(),
    date: new FormControl<Date | null>(null),
    inspector: new FormControl(),
    actions: new FormControl(),
    scope: new FormControl(),
    epis: new FormControl(),
    decontaminationProcedure: new FormControl(),
    wasteDisposal: new FormControl(),
    relevantInformation: new FormControl(),
    buildingEvaluationReport: new FormControl(),

    locations: new FormArray([]),
    // eslint-disable-next-line @typescript-eslint/unbound-method
    geometry: new FormControl({}, Validators.required),

    communication_council_date: new FormControl<Date | null>(null),
    inspection_sampler: new FormControl(),
    inspection_documentation_manager: new FormControl(),
    inspection_duration: new FormControl(),
    inspection_sampling_duration: new FormControl(),
    future_inspector_notes: new FormControl(),
  });

  owner_id: string = '';
  locations?: any[] = [];

  inspectionId: string = '';
  recordId: string = '';
  waiting: boolean = false;

  constructor(
    private readonly http: HttpClient,
    private confirmationService: ConfirmationService,
    private router: Router,
    private route: ActivatedRoute,
    private location: Location,
    private userSvc: UserService,
    private pdfGeneratorService: PdfGeneratorService,
    private detector: ChangeDetectorRef,
    private messageService: MessageService,
    private translate: TranslateService,
  ) {}

  ngAfterViewInit(): void {}

  private clientPermissions() {
    this.canEdit = false;
    this.canDelete = false;
    this.canView = true;
    this.canViewAll = true;
  }

  private providerPermissions() {
    this.canCreate =
      this.user?.hasPermission(
        PermissionScope.INSPECTIONS,
        PermissionType.CREATE,
      ) || false;
    this.canEdit =
      this.user?.hasPermission(
        PermissionScope.INSPECTIONS,
        PermissionType.EDIT,
      ) || false;
    this.canDelete =
      this.user?.hasPermission(
        PermissionScope.INSPECTIONS,
        PermissionType.DELETE,
      ) || false;
    this.canView =
      this.user?.hasPermission(
        PermissionScope.INSPECTIONS,
        PermissionType.VIEW,
      ) || false;
    this.canViewAll =
      this.user?.hasPermission(
        PermissionScope.INSPECTIONS,
        PermissionType.VIEW_ALL,
      ) || false;
  }

  private organizationPermissions() {
    this.canEdit = false;
    this.canDelete = false;
    this.canView =
      this.user?.hasPermission(
        PermissionScope.INSPECTIONS,
        PermissionType.VIEW,
      ) || false;
    this.canViewAll =
      this.user?.hasPermission(
        PermissionScope.INSPECTIONS,
        PermissionType.VIEW_ALL,
      ) || false;
  }

  async ngOnInit() {
    this.changeLocationsUseOptions();
    this.changedeleteWasteOptions();
    this.changeMcaOptions();
    this.changemcaManagementOptions();
    this.changeinspectionTypeOptions();
    //this.addLocation();
    this.user = this.userSvc.getUser();

    this.isClient = this.user?.is_client || false;
    this.isProvider = this.user?.is_provider || false;
    this.isOrganization = this.user?.is_organization || false;

    if (this.isClient) {
      this.clientPermissions();

      const ayto: any = await lastValueFrom(
        this.http.get(
          environment.api + 'localities/by_owner/' + this.user?.owner_id,
        ),
      );
      const record: any = await lastValueFrom(
        this.http.get(
          environment.api + 'expedientes/bylocality/' + ayto.ineInternalId,
        ),
      );

      console.log(record);

      this.providerOwnerId = record.owner_id;
      this.clientInternalId = ayto.ineInternalId;

      //this.owner_id = record.owner_id;
    }

    if (this.isProvider) {
      this.providerPermissions();
      this.owner_id = this.user?.owner_id || '';
    }

    if (this.isOrganization) {
      this.organizationPermissions();
    }

    /*
    this.owner_id = this.user?.owner_id || '';

    if (this.owner_id == '') {
      this.canEdit = false;
      this.canDelete = false;
    }
    else {
      this.canEdit = this.user?.hasPermission(PermissionScope.INSPECTIONS, PermissionType.EDIT) || false;
      this.canDelete = this.user?.hasPermission(PermissionScope.INSPECTIONS, PermissionType.DELETE) || false;
    }

    */

    // eslint-disable-next-line @typescript-eslint/unbound-method
    this.canEdit || this.canCreate ? this.form.enable : this.form.disable();

    const snapshot = this.route.snapshot;

    await this.load(
      snapshot.queryParams['recordId'],
      snapshot.queryParams['id'],
    );

    /*
    this.route.queryParams.subscribe(async (params) => {
      if (params['id']) {
        console.log('before load')
        await this.load(params['id']);
      }
    });
    */
  }

  async load(recordId: string, id: string) {
    console.log('loading inspection', recordId, id);
    console.log('loading inspection');

    this.inspectionId = id;
    this.recordId = recordId;

    this.loading = true;

    let data: any = null;

    try {
      data = await lastValueFrom(
        this.http.get(environment.api + 'inspection/' + id),
      );
      data.recordNumber = recordId;

      this.data = data;

      console.log(data);


      //data.date = new Date(data.date);
      //data.communication_council_date = new Date(data.communication_council_date);
      this.form.patchValue(JSON.parse(JSON.stringify(data)));

      this.form
        .get('communication_council_date')
        ?.patchValue(new Date(data.communication_council_date));
      this.form.get('date')?.patchValue(new Date(data.date));

      this.form.get('locality')?.disable();

      (this.form.get('locations') as FormArray).clear();

      this.pictures = data.locations;

      data.locations.forEach((location: any) => {
        let y = 0;
        let m = 0;
        let d = 0;

        console.log(location);

        if (
          location.sample_withdrawal_date &&
          location.sample_withdrawal_date.includes('T')
        ) {
          //const date = location.sample_withdrawal_date.split('T')[0].split('-');
          const date = location.sample_withdrawal_date.split('T')[0];

          const [yyyy, mm, dd] = date.split('-');
          y = yyyy;
          m = mm;
          d = dd;

          console.log(yyyy, mm, dd);

          location.sample_withdrawal_date = new Date(yyyy, mm, dd);
          console.log(location.sample_withdrawal_date);
        }

        this.addLocation();

        const fg: FormGroup | null = this.form
          .get('locations')
          ?.get('0') as FormGroup | null;

        if (fg !== undefined && fg !== null) {
          fg.get('id')?.patchValue(location.id);
          fg.get('name')?.patchValue(location.name);
          fg.get('samplingStrategy')?.patchValue(location.samplingStrategy);
          fg.get('management')?.patchValue(location.management);
          fg.get('material')?.patchValue(location.material);
          fg.get('mca')?.patchValue(location.mca);

          y > 0 &&
            fg.get('sample_withdrawal_date')?.patchValue(new Date(y, m, d));

          fg.get('pictures')?.patchValue(location.pictures);
          fg.get('picturesLab')?.patchValue(location.picturesLab);
          fg.get('picturesSchemas')?.patchValue(location.picturesSchemas);

          fg.get('picturesToAdd')?.patchValue([]);
          fg.get('picturesLabToAdd')?.patchValue([]);
          fg.get('picturesSchemasToAdd')?.patchValue([]);

          fg.get('picturesToDelete')?.patchValue([]);
          fg.get('picturesLabToDelete')?.patchValue([]);
          fg.get('picturesSchemasToDelete')?.patchValue([]);

          fg.get('risk_location')?.patchValue(location.risk_location);
          fg
            .get('risk_level_location')
            ?.patchValue(location.risk_level_location);
          fg.get('risk_quantity')?.patchValue(location.risk_quantity);
          fg
            .get('risk_level_quantity')
            ?.patchValue(location.risk_level_quantity);
          fg.get('risk_accessibility')?.patchValue(location.risk_accessibility);
          fg
            .get('risk_level_accessibility')
            ?.patchValue(location.risk_level_accessibility);
          fg
            .get('risk_technical_accesibility')
            ?.patchValue(location.risk_technical_accesibility);
          fg
            .get('risk_level_technical_accesibility')
            ?.patchValue(location.risk_level_technical_accesibility);
          fg
            .get('risk_material_status')
            ?.patchValue(location.risk_material_status);
          fg
            .get('risk_level_material_status')
            ?.patchValue(location.risk_level_material_status);
          fg.get('risk_variery')?.patchValue(location.risk_variery);
          fg.get('risk_level_variery')?.patchValue(location.risk_level_variery);
          fg.get('risk_fiability')?.patchValue(location.risk_fiability);
          fg
            .get('risk_level_fiability')
            ?.patchValue(location.risk_level_fiability);
          fg
            .get('risk_number_exposed')
            ?.patchValue(location.risk_number_exposed);
          fg
            .get('risk_level_number_exposed')
            ?.patchValue(location.risk_level_number_exposed);

          fg.get('asbestos_quantity')?.patchValue(location.asbestos_quantity);
          fg
            .get('asbestos_quantity_unit')
            ?.patchValue(location.asbestos_quantity_unit);
          fg
            .get('asbestos_quantity_description')
            ?.patchValue(location.asbestos_quantity_description);

          //
          //fg.get('inspection_data')?.patchValue(JSON.parse(location.inspection_data) || {});
        }
      });

      this.form
        ?.get('communication_council_date')
        ?.setValue(new Date(data.communication_council_date));
      this.form?.get('date')?.setValue(new Date(data.agForestDate));

      console.log(data.inspector);

      this.form?.get('inspector')?.setValue(data.inspector);

      this.form
        ?.get('buildingEvaluationReport')
        ?.setValue(data.buildingEvaluationReport);

      this.form
        ?.get('inspection_documentation_manager')
        ?.setValue(data.inspection_documentation_manager);
      this.form?.get('inspection_sampler')?.setValue(data.inspection_sampler);

      //this.form?.get('epis')?.setValue(data.epis != null ? new Date(data.epis) : new Date(Date.now()));

      //this.form.get('date')?.setValue(data.date);

      //var map: any = document.getElementById('map');
      this.geometrySelected = data?.geometry;
      console.log(this.geometrySelected);

      this.loadingMap = true;
      await this.map?.loadGeometry(this.geometrySelected?.features);
      this.loadingMap = false;

      console.log(this.form);

      this.status = data.status;

      if (this.canEdit) {
        this.canEdit = data.status != 'CLOSED';
      }

      //map?.setAttribute('data-geojsons', JSON.stringify(data?.geometry));
      //map?.connectedCallback();

      this.loading = false;
    } catch (e: any) {
      if (e.error.statusCode == 404) {
        //this.messageService.add({severity:'error', summary:'Error', detail:'Inspección no encontrada'});
        // eslint-disable-next-line angular/timeout-service
        setTimeout(() => {
          this.loading = false;
          this.location.back();
        }, 1000);
      }
    }
  }

  addLocation() {
    console.log('Adding location', location);

    const f = this.form.get('locations') as FormArray;

    this.detector.detach();

    f.insert(
      0,
      new FormGroup({
        id: new FormControl(),
        // eslint-disable-next-line @typescript-eslint/unbound-method
        name: new FormControl('', [Validators.required]),
        samplingStrategy: new FormControl(),
        management: new FormControl(),
        material: new FormControl(),
        mca: new FormControl(),
        sample_withdrawal_date: new FormControl(),
        pictures: new FormControl<
          { filename: string; url: string; data: string }[]
        >([]),
        picturesLab: new FormControl<
          { filename: string; url: string; data: string }[]
        >([]),
        picturesSchemas: new FormControl<
          { filename: string; url: string; data: string }[]
        >([]),

        picturesToAdd: new FormControl<
          { filename: string; url: string; data: string }[]
        >([]),
        picturesLabToAdd: new FormControl<
          { filename: string; url: string; data: string }[]
        >([]),
        picturesSchemasToAdd: new FormControl<
          { filename: string; url: string; data: string }[]
        >([]),

        picturesToDelete: new FormControl<
          { filename: string; url: string; data: string }[]
        >([]),
        picturesLabToDelete: new FormControl<
          { filename: string; url: string; data: string }[]
        >([]),
        picturesSchemasToDelete: new FormControl<
          { filename: string; url: string; data: string }[]
        >([]),

        risk_location: new FormControl(),
        risk_level_location: new FormControl(),
        risk_quantity: new FormControl(),
        risk_level_quantity: new FormControl(),
        risk_accessibility: new FormControl(),
        risk_level_accessibility: new FormControl(),
        risk_technical_accesibility: new FormControl(),
        risk_level_technical_accesibility: new FormControl(),
        risk_material_status: new FormControl(),
        risk_level_material_status: new FormControl(),
        risk_variery: new FormControl(),
        risk_level_variery: new FormControl(),
        risk_fiability: new FormControl(),
        risk_level_fiability: new FormControl(),
        risk_number_exposed: new FormControl(),
        risk_level_number_exposed: new FormControl(),

        asbestos_quantity: new FormControl(),
        asbestos_quantity_unit: new FormControl(),
        asbestos_quantity_description: new FormControl(),
      }),
    );

    this.detector.reattach();
    this.detector.detectChanges();

    //f.at(0).get('sample_withdrawal_date')?.patchValue(new Date(location.sample_withdrawal_date));

    console.log('Location added', f.at(0));
  }

  removeLocation(index: number) {
    (this.form.get('locations') as FormArray).removeAt(index);
  }

  async generateReport() {
    await lastValueFrom(
      this.http.post(environment.api + 'inspection/close', this.data),
    );

    await this.pdfGeneratorService.generatePdf(
      this.pdfContent.nativeElement,
      'inspection',
    );
  }

  openReport() {
    this.confirmationService.confirm({
      key: 'report',
      accept: async () => {},
      reject: (_type: ConfirmEventType) => {},
    });
  }

  async save() {
    if (this.form.valid) {
      this.waiting = true;

      const values: any = this.form.getRawValue();

      values['owner_id'] = this.owner_id;

      /*
      //Hack to fix agForest bad image api
      values.locations.map((location: any) => {
        //location.picturesToAdd = location.pictures.filter((picture: any) => this.pictures.find((loc: any) => loc.pictures.find((locx: any) => locx.data == picture.data)));
        //location.picturesLabToAdd = location.picturesLab.filter((picture: any) => this.pictures.find((loc: any) => loc.picturesLab.find((locx: any) => locx.data != picture.data)));
        //location.picturesSchemasToAdd = location.picturesSchemas.filter((picture: any) => this.pictures.find((loc: any) => loc.picturesSchemas.find((locx: any) => locx.data != picture.data)));

        location.picturesToAdd = location.pictures.filter((picture: any) => picture.data);
        location.picturesLabToAdd = location.picturesLab.filter((picture: any) => picture.data);
        location.picturesSchemasToAdd = location.picturesSchemas.filter((picture: any) => picture.data);

        const dLocation = this.data.locations.find((l: any) => l.name === location.name);
        location.picturesToDelete = dLocation?.pictures.filter((picture: any) => !location.pictures.find((p: any) => p.filename === picture.filename))?.map((picture: any) => picture.filename);
        location.picturesLabToDelete = dLocation?.picturesLab.filter((picture: any) => !location.picturesLab.find((p: any) => p.filename === picture.filename))?.map((picture: any) => picture.filename);
        location.picturesSchemasToDelete = dLocation?.picturesSchemas.filter((picture: any) => !location.picturesSchemas.find((p: any) => p.filename === picture.filename))?.map((picture: any) => picture.filename);

      });
      */

      // eslint-disable-next-line no-console
      console.log('Data sent')
      // eslint-disable-next-line no-console
      console.log(values)

      try {
        await lastValueFrom(
          this.http.put(
            environment.api + 'inspection/' + this.data.agforestId,
            { ...values, status: this.status },
          ),
        );
        this.location.back();
        this.waiting = false;
      } catch (error) {
        console.log(error);
      }
    } else {
      UtilsService.markGroupControlsAsDirty(this.form);
    }
  }

  delete() {
    this.confirmationService.confirm({
      key: 'delete',
      accept: async () => {
        this.deleteLoading = true;
        await lastValueFrom(
          this.http.delete(environment.api + 'inspection/' + this.data.id),
        );
        this.deleteLoading = false;
        this.location.back();
      },
      reject: (_type: ConfirmEventType) => {},
    });
  }

  finishLoading() {
    this.loadingMap = false;
  }

  geometry(e: any) {
    console.log(e);
    this.geometrySelected = e;
    //this.onGeometrySelected.emit(e);
  }

  async getUser(usr: any) {
    const result: any = await lastValueFrom(
      this.http.get<any[]>(environment.api + 'user/' + usr.id),
    );

    delete result.client;
    delete result.permissions;
    delete result.records;

    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return result;
  }

  async downloadTemplate() {
    // let link = document.createElement('a');
    // link.setAttribute('type', 'hidden');
    // link.href = 'assets/templates/In_ins_v2.docx';
    // //link.download = path;
    // document.body.appendChild(link);
    // link.click();
    // link.remove();

    await lastValueFrom(
      this.http.post(environment.api + 'inspection/close', this.data),
    );

    this.status = 'CLOSED';

    const agForestId = this.route.snapshot.queryParams['id'];

    const url = environment.api + 'docx/frominspection/' + agForestId;
    // eslint-disable-next-line angular/document-service
    const link = document.createElement('a');
    link.setAttribute('type', 'hidden');
    link.href = url;
    // eslint-disable-next-line angular/document-service
    document.body.appendChild(link);
    // link.download = 'file.docx';
    link.click();
    link.remove();
  }

  changeLocationsUseOptions() {
    this.locationUseOptions.forEach((option) => {
      option.label = this.translate.instant(option.label);
    });
  }

  changedeleteWasteOptions() {
    this.deleteWasteOptions.forEach((option) => {
      option.label = this.translate.instant(option.label);
    });
  }

  changeMcaOptions() {
    this.mcaOptions.forEach((option) => {
      option.label = this.translate.instant(option.label);
    });
  }

  changemcaManagementOptions() {
    this.mcaManagementOptions.forEach((option) => {
      option.label = this.translate.instant(option.label);
    });
  }

  changeinspectionTypeOptions() {
    this.inspectionTypeOptions.forEach((option) => {
      option.label = this.translate.instant(option.label);
    });
  }
}
