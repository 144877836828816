import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { UserService } from 'src/app/shared/services/user.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-new-pass',
  templateUrl: './new-pass.component.html',
  styleUrls: ['./new-pass.component.scss'],
})
export class NewPassComponent {
  constructor(
    private readonly http: HttpClient,
    private readonly router: Router,
    private userService: UserService,
  ) {
    this.access_token =
      this.router.getCurrentNavigation()?.extras.state?.['access_token'];
    if (!this.access_token) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      this.router.navigate(['/login']);
    }
  }

  form = new FormGroup(
    {
      newPass: new FormControl(null, [
        // eslint-disable-next-line @typescript-eslint/unbound-method
        Validators.required,
        Validators.minLength(6),
      ]),
      repeatPass: new FormControl(null),
    },
    {
      validators: this.mustMatch('newPass', 'repeatPass'),
    },
  );
  access_token?: string;
  loading = false;
  error = false;

  mustMatch(newPass: any, repeatPass: any): ValidatorFn {
    return (form: AbstractControl): ValidationErrors | null => {
      const newPassControlValue = form.get(newPass)?.value;
      const repeatPassControl = form.get(repeatPass);
      const repeatPassControlValue = repeatPassControl?.value;
      if (newPassControlValue === repeatPassControlValue) {
        return null;
      }
      repeatPassControl?.setErrors({ passwordMismatchError: true });
      return { passwordMismatchError: true };
    };
  }

  async savePass() {
    if (this.form.invalid) return;
    this.loading = true;
    this.error = false;
    await this.http
      .post(environment.api + 'user/reset', {
        access_token: this.access_token,
        password: this.form.value.repeatPass,
      })
      .toPromise()
      .then(async (_res) => {
        this.userService.logout();
        await this.router.navigate(['/login']);
      })
      .catch((_err) => {
        this.error = true;
      });
    this.loading = false;
  }
}
