import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AsbestosStats } from './domain/asbestos-stats.interface';
import { GraphicStats } from './domain/graphic-stats.interface';

@Injectable({
  providedIn: 'root',
})
export class StatsService {
  constructor(private http: HttpClient) {}

  async overall(_e: any) {
    const result = await lastValueFrom(
      this.http.get(environment.api + 'retiradas/autonomies'),
    );

    return result;
  }

  async byAutonomy(autonomy: number) {
    const result: AsbestosStats = await lastValueFrom(
      this.http.get<AsbestosStats>(
        environment.api + 'retiradas/autonomy/' + autonomy,
      ),
    );
    return result;
  }

  async byProvince(province: number) {
    const result: AsbestosStats = await lastValueFrom(
      this.http.get<AsbestosStats>(
        environment.api + 'retiradas/province/' + province,
      ),
    );
    return result;
  }

  async byMunicipe(municipio: number) {
    const result: AsbestosStats = await lastValueFrom(
      this.http.get<AsbestosStats>(
        environment.api + 'retiradas/municipe/' + municipio,
      ),
    );
    return result;
  }

  async getGraphic(graphicType: string, locationType: string, code: string) {
    const result: GraphicStats = await lastValueFrom(
      this.http.get<GraphicStats>(
        environment.api +
          `retiradas/graphic/${graphicType}/${locationType}/${code}`,
      ),
    );
    return result;
  }

  async getStats(locationType: string, code: string) {
    const result: GraphicStats = await lastValueFrom(
      this.http.get<GraphicStats>(
        environment.api + `retiradas/stats/${locationType}/${code}`,
      ),
    );
    return result;
  }
}
