import { Location } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  OnInit,
  Renderer2,
  signal,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { Sidebar } from 'primeng/sidebar';
import { UserService } from '../shared/services/user.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-admin-navigation',
  templateUrl: './admin-navigation.component.html',
  styleUrls: ['./admin-navigation.component.scss'],
})
export class AdminNavigationComponent implements OnInit, AfterViewInit {
  selectedItem: string = 'clients';

  sidebarVisible = signal(false);

  mustShowBurguer = signal(false);

  innerWidth: number = 0;

  user?: any;
  showPopup = false;

  selectedLanguage = 'es';

  @ViewChild('aside') sidenav!: Sidebar;

  constructor(
    public router: Router,
    private detector: ChangeDetectorRef,
    private userSvc: UserService,
    private location: Location,
    private renderer: Renderer2,
    private translate: TranslateService,
  ) {}
  ngAfterViewInit(): void {
    // eslint-disable-next-line angular/window-service
    this.innerWidth = window.innerWidth;

    if (this.innerWidth < 1048) {
      this.mustShowBurguer.set(true);
    } else {
      this.mustShowBurguer.set(false);
    }

    if (!this.user.is_organization) {
      this.showPopupDlg();
    }
  }

  showPopupDlg() {
    // eslint-disable-next-line angular/document-service
    this.renderer.addClass(document.body, 'overflow_hidden');
    this.showPopup = true;
  }

  closePopup(): void {
    this.showPopup = false;
    // eslint-disable-next-line angular/document-service
    this.renderer.removeClass(document.body, 'overflow_hidden');
    this.location.back();
  }
  ngOnInit(): void {
    this.user = this.userSvc.getUser();

    this.selectedLanguage = this.translate.currentLang;

    if (!this.user.is_organization) {
      this.showPopupDlg();
    }

    this.router.events.subscribe((val) => {
      // Chequeamos NavigationStart para evitar errores
      if (this.user.is_organization) {
        if (val.constructor.name === 'NavigationEnd') {
          // Obtenemos la ruta actual
          const currentRoute = this.router.url;
          this.detector.detach();

          if (currentRoute.includes('clients')) {
            this.selectedItem = 'clients';
          }

          if (currentRoute.includes('users')) {
            this.selectedItem = 'users';
          }

          if (currentRoute.includes('providerclients')) {
            this.selectedItem = 'providerclients';
          }

          if (currentRoute.includes('providers')) {
            this.selectedItem = 'providers';
          }

          if (currentRoute.includes('pending-contracts')) {
            this.selectedItem = 'pending_contracts';
          }

          if (currentRoute.includes('pending-auth-contracts')) {
            this.selectedItem = 'pending_auth_contracts';
          }

          if (currentRoute.includes('pending-reapproval-contracts')) {
            this.selectedItem = 'pending_reapproval_contracts';
          }

          if (currentRoute.includes('bills_emitted')) {
            this.selectedItem = 'bills_emitted';
          }

          (this.selectedItem);

          this.detector.reattach();
          this.detector.detectChanges();
        }
      }
    });
  }

  changeTab(tab: string) {
    this.selectedItem = tab;
  }

  toggleSidebar() {
    if (this.innerWidth > 1024) {
      this.sidebarVisible.set(true);
    } else {
      this.sidebarVisible.set(!this.sidebarVisible());
    }
  }

  onResize(event: any) {
    this.detector.detach();
    this.innerWidth = event.target.innerWidth;

    if (this.innerWidth < 1048) {
      this.mustShowBurguer.set(true);
    } else {
      this.mustShowBurguer.set(false);
    }

    this.detector.reattach();
    this.detector.detectChanges();
  }
}
