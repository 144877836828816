import { HttpClient } from '@angular/common/http';
import {
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  signal,
  WritableSignal,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NotifyFormService } from 'src/app/shared/services/notify-form.service';
import { environment } from 'src/environments/environment';
import { lastValueFrom } from 'rxjs';

@Component({
  selector: 'app-notify-location',
  templateUrl: './notify-location.component.html',
  styleUrls: ['./notify-location.component.scss'],
})
export class NotifyLocationComponent implements OnInit {
  public form = this.formService.form.controls.step_1;
  public images: any[] = [];
  public filteredTowns: string[] = [];

  @Input() ine: string = '';
  @Input() internalId?: number;

  public localities: any[] = [];

  public locality?: any;

  loadingMap: boolean = true;

  coordinatesSelected: boolean = false;

  localityName!: WritableSignal<string>;

  constructor(
    private formService: NotifyFormService,
    public router: Router,
    private http: HttpClient,
    private route: ActivatedRoute,
    private detector: ChangeDetectorRef,
  ) {}

  finishLoading() {
    this.loadingMap = false;
  }

  async ngOnInit() {
    this.localityName = signal('');

    this.detector.detach();

    this.localities = await lastValueFrom(
      this.http.get<any[]>(environment.api + 'localities/'),
    );

    this.form.patchValue({ ine: this.ine });
    this.coordinatesSelected = false;
    if (this.internalId) {
      this.locality = await lastValueFrom(
        this.http.get(environment.api + 'locality/' + this.internalId),
      );

      this.localityName.set(this.locality.name);
      this.form.patchValue({ city: this.locality.name });

      localStorage.setItem('internalId', this.internalId?.toString() || '');
    } else {
      const loc = this.localities.filter((l) => l.ine === this.ine);

      if (loc.length > 0) {
        this.locality = loc[0];
        this.internalId = this.locality.internalId;
        this.localityName.set(this.locality.name);
        this.form.patchValue({ city: this.locality.name });

        localStorage.setItem('internalId', this.internalId?.toString() || '');
      }
    }

    localStorage.setItem('ine', this.ine);

    if (this.form.value.images && this.form.value.images[0] !== '') {
      this.images = this.form.value.images;
    }

    this.detector.reattach();
    this.detector.detectChanges();
  }

  async validate() {
    (this.form);

    if (this.form.valid) {
      await this.router.navigate(['/notify/contact'], {
        queryParams: { ine: this.ine, internalId: this.internalId },
      });
    } else {
      this.form.markAllAsTouched();
    }
  }

  setCoordinates(param: { coordinates: [number, number]; location: string }) {
    this.coordinatesSelected = true;

    this.form.patchValue({ coordinates: param.coordinates });
    this.form.patchValue({ address: param.location });
  }

  updateImages(images: string[]) {
    this.images = images;
    this.form.patchValue({ images: this.images });
  }
}
