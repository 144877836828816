import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { lastValueFrom } from 'rxjs';
import {
  User,
  UserService,
  PermissionScope,
  PermissionType,
} from 'src/app/shared/services/user.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-retiradas-list',
  templateUrl: './retiradas-list.component.html',
  styleUrls: ['./retiradas-list.component.css'],
})
export class RetiradasListComponent implements OnInit {
  user?: User;
  canCreate: boolean = false;
  canEdit: boolean = false;
  canDelete: boolean = false;

  canView: boolean = false;
  canViewAll: boolean = false;
  isClient: boolean = false;
  isProvider: boolean = false;
  isOrganization: boolean = false;

  providerOwnerId: string = '';
  clientInternalId?: number;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private userSvc: UserService,
    private http: HttpClient,
  ) {}

  owner_id?: string = '';

  private clientPermissions() {
    this.canCreate = false;
    this.canEdit = false;
    this.canDelete = false;
    this.canView = true;
    this.canViewAll = true;
  }

  private providerPermissions() {
    this.canCreate =
      this.user?.hasPermission(
        PermissionScope.RECORDS,
        PermissionType.CREATE,
      ) || false;
    this.canEdit =
      this.user?.hasPermission(PermissionScope.RECORDS, PermissionType.EDIT) ||
      false;
    this.canDelete =
      this.user?.hasPermission(
        PermissionScope.RECORDS,
        PermissionType.DELETE,
      ) || false;
    this.canView =
      this.user?.hasPermission(PermissionScope.RECORDS, PermissionType.VIEW) ||
      false;
    this.canViewAll =
      this.user?.hasPermission(
        PermissionScope.RECORDS,
        PermissionType.VIEW_ALL,
      ) || false;
  }

  private organizationPermissions() {
    this.canCreate = false;
    this.canEdit = false;
    this.canDelete = false;
    this.canView =
      this.user?.hasPermission(PermissionScope.RECORDS, PermissionType.VIEW) ||
      false;
    this.canViewAll =
      this.user?.hasPermission(
        PermissionScope.RECORDS,
        PermissionType.VIEW_ALL,
      ) || false;
  }

  async ngOnInit() {
    this.user = this.userSvc.getUser();

    this.isClient = this.user?.is_client || false;
    this.isProvider = this.user?.is_provider || false;
    this.isOrganization = this.user?.is_organization || false;

    this.owner_id = this.user?.owner_id || '';

    if (this.isClient) {
      this.clientPermissions();

      const ayto: any = await lastValueFrom(
        this.http.get(environment.api + 'localities/by_owner/' + this.owner_id),
      );
      const record: any = await lastValueFrom(
        this.http.get(
          environment.api + 'expedientes/bylocality/' + ayto.ineInternalId,
        ),
      );

      this.providerOwnerId = record.owner_id;
      this.owner_id = record.owner_id;

      this.clientInternalId = ayto.ineInternalId;
    }

    if (this.isProvider) {
      this.providerPermissions();
      this.owner_id = this.user?.owner_id || '';
    }

    if (this.isOrganization) {
      this.organizationPermissions();
    }
  }

  async selected(data: any) {
    await this.router.navigate(['list'], {
      queryParams: { id: data.id },
      relativeTo: this.route,
    });
  }
}
