import {
  ChangeDetectorRef,
  Component,
  OnInit,
  ChangeDetectionStrategy,
  AfterViewInit,
  Input,
  signal,
  WritableSignal,
  Output,
  EventEmitter,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { lastValueFrom } from 'rxjs';
import { MessageService } from 'primeng/api';
import { GlobalService } from '../../shared/services/global.service';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-sites',
  templateUrl: './locality.component.html',
  styleUrls: ['./locality.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LocalityComponent implements OnInit, AfterViewInit {
  locality: any;

  @Input() ine: string = '';

  @Output() embeddedChange = new EventEmitter<boolean>();

  internalId?: number;
  ineId: string = '';

  totalRecords: number = 0;

  showWarning: boolean = false;

  @Input() embedded: boolean = false;

  errorCondition!: WritableSignal<boolean>;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private http: HttpClient,
    public readonly global: GlobalService,
    private messgeSvc: MessageService,
    private detector: ChangeDetectorRef,
  ) {
  }
  async ngAfterViewInit() {
    await this.loadLocality();
  }

  async ngOnInit() {
    // Accedemos al unico parametro de la ruta
    this.ineId = this.route.snapshot.params['ine'];
    this.errorCondition = signal(false);
    this.embedded = this.global.embedded;

    await this.loadLocality();
  }

  async loadLocality() {
    if (!this.ineId) {
      this.messgeSvc.add({
        severity: 'error',
        summary: 'Error',
        detail: 'El parametro proporcionado en la url no es valido',
      });

      this.locality = {
        name: 'Parametro invalido',
      };

      this.errorCondition.set(true);
      return;
    }

    try {
      this.detector.detach();

      this.locality = await lastValueFrom(
        this.http.get(environment.api + 'locality/' + this.ineId, {}),
      );

      this.internalId = this.locality.internalId;

      this.errorCondition.set(false);

      if (this.locality.showInPublic) {
        this.ine = this.locality.ine;
      } else {
        this.showWarning = true;
        this.ine = '';
      }

      this.detector.reattach();
      this.detector.detectChanges();
    } catch (e) {
      (e);
    }
  }

  fixUrl(url: string) {
    if (!url) return '';
    if (url.startsWith('http://') || url.startsWith('https://')) return url;
    if (url == 'http') return '';
    return 'http://' + url;
  }

  async notifyWaste() {
    await this.router.navigate(['/notify/location'], {
      queryParams: { ine: this.ineId, internalId: this.internalId },
    });
  }
}
