/* eslint-disable @typescript-eslint/unbound-method */
/* eslint-disable @typescript-eslint/ban-types */
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from 'src/app/shared/services/user.service';
import { StatsService } from '../Stats.service';
import { MapComponent } from 'src/app/shared/components/map/map.component';
import { TranslateService } from '@ngx-translate/core';

export type GraphicDataSus = {
  keys: string[];
  values: string[];
};

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css'],
})
export class DashboardComponent implements OnInit, AfterViewInit {
  loadingMap: boolean = true;
  layer_style = 'regionLimits';

  current_code: string = '';
  graphic_data_sus: any = null;
  graphic_data_urban: any = null;
  stats_data: any = null;

  sus_data: any = null;
  evo_data: any = null;

  colors = ['#fdae61', '#1a9641', '#a6d96a', '#d7191c'];

  @ViewChild('map') map!: MapComponent;

  levels: { key: string; method: Function; caption: string }[] = [
    {
      key: 'overall',
      method: this.statsSvc.overall,
      caption: 'Comunidad Autonoma',
    },
    {
      key: 'autonomy',
      method: this.statsSvc.byAutonomy,
      caption: 'Provincia',
    },
    {
      key: 'province',
      method: this.statsSvc.byProvince,
      caption: 'Municipio',
    },
    {
      key: 'municipe',
      method: this.statsSvc.byMunicipe,
      caption: '',
    },
  ];

  breadcrumbLevels: {
    id: string;
    label: string;
    code: string;
    caption: string;
  }[] = [];

  currentLevel?: { key: string; method: Function; caption: string };
  currentLevelIndex: number = 0;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private userSvc: UserService,
    private statsSvc: StatsService,
    private detector: ChangeDetectorRef,
    private translate: TranslateService,
  ) {}
  async ngAfterViewInit(): Promise<void> {
    this.loadingMap = true;
    const geojson = await this.currentLevel?.method.call(this.statsSvc, 0);
    await this.map.loadGeometry([geojson]);
  }

  async ngOnInit() {

    this.currentLevel = this.levels[this.currentLevelIndex];
    this.translate.get('withdrawals.map.all').subscribe((allLabel) => {
      this.breadcrumbLevels.push({
        id: this.currentLevel!.key,
        label: allLabel,
        code: '',
        caption: this.currentLevel!.caption,
      });
    });
  }

  finishLoading() {
    this.loadingMap = false;
  }

  async nextLevel(e: any) {
    if (this.currentLevelIndex == this.levels.length - 1) {
      return;
    }

    this.currentLevelIndex++;
    this.currentLevel = this.levels[this.currentLevelIndex];
    this.current_code = e?.feature?.values_.code;
    this.breadcrumbLevels = [
      ...this.breadcrumbLevels,
      {
        id: this.currentLevel.key,
        label: e.feature.values_.tooltip,
        code: this.current_code,
        caption: this.currentLevel.caption,
      },
    ];

    this.loadingMap = true;

    if (this.currentLevel.key == 'municipe') {
      this.layer_style = 'susceptibility';
    } else {
      this.layer_style = 'regionLimits';
    }

    const geojson = await this.currentLevel.method.call(
      this.statsSvc,
      e?.feature?.values_.code,
    );

    this.currentLevel.key == 'municipe'
      ? await this.map.loadGeometry(geojson)
      : await this.map.loadGeometry([geojson]);

    this.graphic_data_sus = await this.statsSvc.getGraphic(
      'susceptibility',
      this.currentLevel.key,
      this.current_code,
    );
    this.graphic_data_urban = await this.statsSvc.getGraphic(
      'urban_evolution',
      this.currentLevel.key,
      this.current_code,
    );

    this.stats_data = await this.statsSvc.getStats(
      this.currentLevel.key,
      this.current_code,
    );

    this.sus_data = this.convertToBasicData(this.graphic_data_sus, true);
    this.evo_data = this.convertToBasicData(this.graphic_data_urban);

  }

  async handleBreadcrumb(e: any) {

    if (e.item.code == '') {
      this.currentLevelIndex = 0;
      this.currentLevel = this.levels[this.currentLevelIndex];

      if (this.currentLevel.key == 'municipe') {
        this.layer_style = 'susceptibility';
      } else {
        this.layer_style = 'regionLimits';
      }

      const geojson = await this.currentLevel?.method.call(this.statsSvc, 0);

      await this.map.loadGeometry([geojson]);
    } else {
      this.currentLevelIndex = this.breadcrumbLevels.findIndex(
        (item) => item.id == e.item.id,
      );

      if (this.currentLevelIndex == this.breadcrumbLevels.length - 1) {
        return;
      }

      this.currentLevel = this.levels[this.currentLevelIndex];

      if (this.currentLevel.key == 'municipe') {
        this.layer_style = 'susceptibility';
      } else {
        this.layer_style = 'regionLimits';
      }

      this.current_code = e.item.code;

      const geojson = await this.currentLevel?.method.call(
        this.statsSvc,
        e.item.code,
      );

      await this.map.loadGeometry([geojson]);

      this.graphic_data_sus = await this.statsSvc.getGraphic(
        'susceptibility',
        this.currentLevel.key,
        this.current_code,
      );
      this.graphic_data_urban = await this.statsSvc.getGraphic(
        'urban_evolution',
        this.currentLevel.key,
        this.current_code,
      );

      this.stats_data = await this.statsSvc.getStats(
        this.currentLevel.key,
        this.current_code,
      );
      this.sus_data = this.convertToBasicData(this.graphic_data_sus, true);
      this.evo_data = this.convertToBasicData(this.graphic_data_urban);
    }

    this.breadcrumbLevels = this.breadcrumbLevels.filter((item) => {
      return this.breadcrumbLevels.indexOf(item) <= this.currentLevelIndex;
    });
  }

  convertToBasicData(data: GraphicDataSus, showColors: boolean = false) {
    const labels = data.keys;
    const values = data.values;

    return !showColors
      ? {
          labels: labels,
          datasets: [{ label: 'Evolucion Urbana', data: values }],
        }
      : {
          labels: labels,
          datasets: [
            {
              label: 'Evolucion Urbana',
              data: values,
              backgroundColor: this.colors,
            },
          ],
        };
  }

  translateCaptionLevels(
    caption: 'Comunidad Autonoma' | 'Provincia' | 'Municipio' | '',
  ): string {
    switch (caption) {
      case 'Comunidad Autonoma':
        return 'withdrawals.map.autonomous';
      case 'Provincia':
        return 'withdrawals.map.province';
      case 'Municipio':
        return 'withdrawals.map.municipality';
      default:
        return '';
    }
  }
}
