import { DOCUMENT, Location } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  inject,
  Inject,
  OnChanges,
  OnInit,
  Sanitizer,
  SimpleChanges,
  ViewChild,
  Input
} from '@angular/core';
import { Router } from '@angular/router';
import { MenuItem } from 'primeng/api';
import { Observable, Subscription, fromEvent } from 'rxjs';
import {
  PermissionScope,
  PermissionType,
  User,
  UserService,
} from 'src/app/shared/services/user.service';
import { GlobalService } from '../../services/global.service';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, AfterViewInit {
  isMd: boolean = false;
  resizeObs$!: Observable<Event>;
  resizeSubs$!: Subscription;

  @Input() lang: string = '';

  @ViewChild('mn') mn!: ElementRef;

  visible: boolean = true;

  user?: User;
  canViewAdmin: boolean = false;
  canViewExpedientes: boolean = false;
  canViewInspecciones: boolean = false;
  canViewRetiradas: boolean = false;
  isClient: boolean = false;
  isProvider: boolean = false;
  isOrganization: boolean = false;

  sanitizer: Sanitizer = inject(Sanitizer);
  embedded: boolean = false;
  currentLang: string = '';

  menu: MenuItem[] = [];

  menuItems: MenuItem[] = [
    {
      label: 'ui.menu.cuenta',
      items: [
        {
          label: 'ui.menu.miCuenta',
          icon: 'pi pi-fw pi-user',
          routerLink: ['/account/profile'],
        },
      ],
    },
    {
      label: 'ui.menu.general',
      items: [
        {
          label: 'ui.menu.admin',
          icon: 'pi pi-fw pi-user-edit',
          routerLink: ['/admin/clients'],
          access: 'organization',
          visible: true,
        },
        {
          label: 'ui.menu.records',
          icon: 'pi pi-fw pi-copy',
          routerLink: ['/records'],
          visible: true,
        },
        {
          label: 'ui.menu.inspections',
          icon: 'pi pi-fw pi-file-edit',
          routerLink: ['/inspections'],
          visible: true,
        },
        {
          label: 'ui.menu.withdrawals',
          icon: 'pi pi-fw pi-wrench',
          routerLink: ['/withdrawals'],
          visible: true,
        },
      ],
    },
  ];

  constructor(
    public readonly userService: UserService,
    private router: Router,
    private location: Location,
    private detector: ChangeDetectorRef,
    @Inject(DOCUMENT) private document: Document,
    public global: GlobalService,
    public translate: TranslateService,
  )
  {
    this.currentLang = translate.currentLang;

    translate.onDefaultLangChange.subscribe((event: LangChangeEvent) => {
      this.currentLang = event.lang;
      this.buildMenu();
      this.detector.detectChanges();
    });

  }


  updateVisibility(): void {
    this.visible = false;
    // eslint-disable-next-line angular/timeout-service
    setTimeout(() => (this.visible = true), 0);
  }

  ngAfterViewInit(): void {

    this.translate.get('ui.menu.cuenta').subscribe((res) => {
       (res);
    });

    this.user = this.userService.getUser();

    this.isClient = this.user?.is_client || false;
    this.isProvider = this.user?.is_provider || false;
    this.isOrganization = this.user?.is_organization || false;

    this.canViewAdmin = this.isOrganization || false;
    this.canViewExpedientes =
      this.user?.hasPermission(
        PermissionScope.RECORDS,
        PermissionType.VIEW_ALL,
      ) || false;
    this.canViewInspecciones =
      this.user?.hasPermission(
        PermissionScope.INSPECTIONS,
        PermissionType.VIEW_ALL,
      ) || false;
    this.canViewRetiradas =
      this.user?.hasPermission(
        PermissionScope.WITHDRAWALS,
        PermissionType.VIEW_ALL,
      ) || false;

    this.buildMenu();

    this.mn.nativeElement = this.menu;
  }

  buildMenu() {
    this.menu = [];

    this.menu = [
      {
        label: this.translate.instant('ui.menu.cuenta'),
        items: [
          {
            label: this.translate.instant('ui.menu.miCuenta'),
            icon: 'pi pi-fw pi-user',
            routerLink: ['/account/profile'],
          },
        ],
      },
      {
        label: this.translate.instant('ui.menu.general'),
        items: [
          {
            label: this.translate.instant('ui.menu.admin'),
            icon: 'pi pi-fw pi-user-edit',
            routerLink: ['/admin/clients'],
            access: 'organization',
            visible: this.canViewAdmin,
          },
          {
            label: this.translate.instant('ui.menu.records'),
            icon: 'pi pi-fw pi-copy',
            routerLink: ['/records'],
            visible: this.canViewExpedientes,
          },
          {
            label: this.translate.instant('ui.menu.inspections'),
            icon: 'pi pi-fw pi-file-edit',
            routerLink: ['/inspections'],
            visible: this.canViewInspecciones,
          },
          {
            label: this.translate.instant('ui.menu.withdrawals'),
            icon: 'pi pi-fw pi-wrench',
            routerLink: ['/withdrawals'],
            visible: this.canViewRetiradas,
          },
        ],
      },
    ];
  }

  ngOnInit(): void {
    this.embedded = this.global.embedded;

    this.userService.loginEmmiter.subscribe((_user) => {
      this.user = this.userService.getUser();

      this.isClient = this.user?.is_client || false;
      this.isProvider = this.user?.is_provider || false;
      this.isOrganization = this.user?.is_organization || false;

      this.canViewAdmin = this.isOrganization || false;
      this.canViewExpedientes =
        this.user?.hasPermission(
          PermissionScope.RECORDS,
          PermissionType.VIEW_ALL,
        ) || false;
      this.canViewInspecciones =
        this.user?.hasPermission(
          PermissionScope.INSPECTIONS,
          PermissionType.VIEW_ALL,
        ) || false;
      this.canViewRetiradas =
        this.user?.hasPermission(
          PermissionScope.WITHDRAWALS,
          PermissionType.VIEW_ALL,
        ) || false;

      this.buildMenu();
    });

    // eslint-disable-next-line angular/window-service
    if (window.innerWidth <= 414) {
      this.isMd = false;
    } else {
      this.isMd = true;
    }
    this.resizeObs$ = fromEvent(window, 'resize');
    this.resizeSubs$ = this.resizeObs$.subscribe((_e) => {
      // eslint-disable-next-line angular/window-service
      if (window.innerWidth <= 414) {
        this.isMd = false;
      } else {
        this.isMd = true;
      }
    });
  }

  goDown() {
    const element = this.document.getElementById('footer');
    element?.scrollIntoView({ behavior: 'smooth' });
  }

  getBack() {
    this.location.back();
  }
}
