<!--Boton volver-->
<a *ngIf="!embedded" class="flex items-center text-am-primary-turquoise-100 font-bold mt-10 ml-10" (click)="getBack()">
    <i class="pi pi-angle-left mr-2"></i>
    {{'admin.back' | translate}}
</a>

<!--Div con Titulo y botones guardar y cancelar-->
<div class="flex item-center w-full my-14">
    <h2 class="flex-grow ml-10 text-xl font-bold text-am-primary-navy-100">{{form.get('firstName')?.value || form.get('lastName')?.value ? ((form.get('firstName')?.value || '') + ' ' + (form.get('lastName')?.value || '')) : 'Nuevo usuario'}}</h2>

    <div class="mr-8  flex gap-x-4">
        <p-button *ngIf="user" pRipple  [label]="'admin.deleteUser' | translate" icon="pi pi-trash"  [outlined]="true"
            severity="secondary" iconPos="left"  size="small" (click)="delete()" [disabled]="deleteLoading" [loading]="deleteLoading"></p-button>
        <p-button pRipple [label]="'Guardar'" [style]="{'minWidth':'160px'}"
            iconPos="left"  size="small" (click)="save()" [loading]="loading" [disabled]="deleteLoading"></p-button>
    </div>
</div>


<!--Div Formulario datos de usuario-->
<div class=" flex flex-wrap items-start ml-10 gap-10">
    <!--Email-->
    <div class=" flex flex-col w-1/4 p-input-underline">
        <label for="email" class="font-medium text-sm text-am-secondary-navy-60 ">{{'admin.email' | translate}}</label>
        <input id="email" type="email" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$" pInputText [formControl]="form.get('email')" [placeholder]="'admin.placeholder3' | translate" maxlength="50">
        <small *ngIf="errors['EMAIL_ALREADY_EXIST']" class="text-red-500">{{'admin.alreadyExists' | translate}}</small>
    </div>
    <!--Nombre-->
    <div class=" flex flex-col w-1/4 p-input-underline">
        <label for="firstName" class="font-medium text-sm text-am-secondary-navy-60 ">{{'admin.name' | translate}}</label>
        <input id="firstName" type="text" pInputText [formControl]="form.get('firstName')" [placeholder]="'admin.placeholder1' | translate" maxlength="30">
    </div>
    <!--Apellidos-->
    <div class=" flex flex-col w-1/4 p-input-underline">
        <label for="lastName" class="font-medium text-sm text-am-secondary-navy-60 ">{{'admin.surnames' | translate}}</label>
        <input id="lastName" type="text" pInputText [formControl]="form.get('lastName')" [placeholder]="'admin.placeholder2' | translate" maxlength="30">
    </div>
    <!--Contraseña-->
    <div class=" flex flex-col w-1/4 p-input-underline">
        <label for="password" class="font-medium text-sm text-am-secondary-navy-60 ">{{'admin.password' | translate}}</label>
        <p-password id="password" autocomplete="new-password" [formControl]="form.get('password')" [toggleMask]="true" [placeholder]="'admin.password' | translate" styleClass="w-full" inputStyleClass="min-w-full" [feedback]="false" maxlength="35"></p-password>
    </div>
    <!--Rol-->
    <div class=" flex flex-col w-1/4 p-input-underline">
        <label for="role" class="font-medium text-sm text-am-secondary-navy-60">{{'admin.role' | translate}}</label>
        <p-dropdown id="role" placeholder="Ej.Inspector" [options]="roles" optionLabel="name" [formControl]="form.get('role')" [style]="{'minWidth':'100%'}" (onChange)="roleChange($event)"></p-dropdown>
    </div>
</div>

<div class="ml-10">
    <h2 class="font-bold mt-10">{{'admin.permissions' | translate}}</h2>
    <app-permissions #ap editable="true" (permissionsChange)="permissionsChange($event)"></app-permissions>
</div>


<p-confirmDialog key="delete" #cd [style]="{ width: '50vw' }">
    <ng-template pTemplate="header">
        <h3 class="font-bold">{{'admin.deleteUser' | translate}}</h3>
    </ng-template>
    <ng-template pTemplate="message">
        <div style="width:100%" class="text-sm text-center font-semibold w-full">
            <div>
                {{'admin.sureToDelete1' | translate}}<strong>{{user?.email}}</strong>{{'admin.sureToDelete2' | translate}}
            </div>
            <div>
                {{'admin.sureToDelete3' | translate}}
            </div>
        </div>
    </ng-template>
    <ng-template pTemplate="footer">
        <p-button type="button" [label]="'admin.cancel' | translate" (click)="cd.reject()" size="small" [outlined]="true"></p-button>
        <p-button type="button" [label]="'admin.deleteUser' | translate" (click)="cd.accept()" size="small" severity="danger"></p-button>
    </ng-template>
</p-confirmDialog>

<p-confirmDialog key="addRecord" #ad [style]="{ 'max-width': '40vw',  'width': '400px'}" class="add-record">
    <ng-template pTemplate="header">
        <div class="flex items-center justify-between w-full">
            <h3 class="font-bold">{{'admin.addRecord' | translate}}</h3>
            <button pButton type="button" icon="pi pi-times" class="p-button-text p-button-rounded p-button-plain " (click)="ad.reject()"></button>
        </div>
    </ng-template>
    <ng-template pTemplate="message">
        <app-select-record #sr (selected)="recordSelected($event)"></app-select-record>
    </ng-template>
    <ng-template pTemplate="footer"></ng-template>
</p-confirmDialog>

