import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { lastValueFrom } from 'rxjs';
import { UserService } from 'src/app/shared/services/user.service';
import { StoredBillStoreService } from '../stored-bill-store.service';

@Component({
  selector: 'app-stored-bill-detail-bill',
  templateUrl: './stored-bill-detail.component.html',
  styleUrls: ['./stored-bill-detail.component.css'],
})
export class StoredBillDetailComponent implements OnInit {
  factura: any = null;
  lineas: any = null;
  baseImponible = 0;
  contratos = 0;

  user: any = null;

  totalRecords: number = 0;
  loading = false;

  constructor(
    private http: HttpClient,
    private userService: UserService,
    public store: StoredBillStoreService,
  ) {}

  ngOnInit() {
    this.store.selectedBill$.subscribe((bill) => {
      this.loading = true;

      this.factura = bill;
      this.lineas = bill.lines;
      this.contratos = bill.lines.length;
      this.baseImponible = bill.baseImponible;

      this.loading = false;
    });

    this.user = this.userService.user;

    (this.user);

    //this.getFactura();
  }
}
