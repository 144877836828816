import { SocialAuthService } from '@abacritt/angularx-social-login';
import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import {
  PermissionScope,
  UserService,
} from 'src/app/shared/services/user.service';
import { environment } from 'src/environments/environment';
import { lastValueFrom } from 'rxjs';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent {
  public form = new FormGroup({
    // eslint-disable-next-line @typescript-eslint/unbound-method
    email: new FormControl(null, [Validators.required, Validators.email]),
    // eslint-disable-next-line @typescript-eslint/unbound-method
    password: new FormControl(null, [Validators.required]),
    remember_password: new FormControl(false),
  });

  constructor(
    private socialAuthService: SocialAuthService,
    private readonly http: HttpClient,
    private readonly router: Router,
    private readonly userService: UserService,
  ) {}

  errorLogin = false;

  async login() {
    if (this.form.valid) {
      try {
        const response: any = await lastValueFrom(
          this.http.post(environment.api + 'user/login', this.form.value),
        );

        const user = this.userService.login(
          response.access_token,
          this.form.value.remember_password ?? false,
          response.permissions,
          response.is_client,
          response.is_provider,
          response.is_organization,
        );

        if (user.checkHasViewPermission(PermissionScope.ADMIN)) {
          await this.router.navigate(['/admin/clients']);
          return;
        }

        if (user.checkHasViewPermission(PermissionScope.RECORDS)) {
          await this.router.navigate(['/records']);
        } else {
          await this.router.navigate(['/account/profile']);
        }
      } catch (error: any) {
        if (error.status === 401) {
          this.errorLogin = true;
        }
      }
    } else {
      this.form.markAllAsTouched();
    }
  }
}
