import { HttpClient } from '@angular/common/http';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  FormGroup,
} from '@angular/forms';
import { MessageService } from 'primeng/api';
import { lastValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CurrentContractStoreService } from '../current-store.service';
import { Location } from '@angular/common';
import { RequestAproval } from '../request-aproval.interface';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-edit-contract-independent-detail',
  templateUrl: './edit-contract-independent-detail.component.html',
  styleUrls: ['./edit-contract-independent-detail.component.css'],
})
export class EditContractIndependentDetailComponent implements OnInit {
  client: any = null;
  contract: any = null;

  baseConditions: any[] = [];
  extraConditions: any[] = [];
  currentConditions: any[] = [];

  stateOptions: any[] = [
    { label: 'contracts.general.no', value: false },
    { label: 'contracts.general.yes', value: true },
  ];

  formExtras!: UntypedFormGroup;

  constructor(
    private store: CurrentContractStoreService,
    private http: HttpClient,
    private fb: UntypedFormBuilder,
    private messageSvc: MessageService,
    private location: Location,
    private detector: ChangeDetectorRef,
    private translate: TranslateService,
  ) {}

  changeYesNo() {
    this.stateOptions.forEach((option) => {
      option.label = this.translate.instant(option.label);
    });
  }

  async ngOnInit() {
    this.store.selectedContract$.subscribe(async (contract) => {
      this.detector.detach();
      this.changeYesNo();

      this.contract = contract;
      this.extraConditions = await this.getExtraConditions();
      this.currentConditions = await this.getCurrentConditions();

      this.formExtras = this.fb.group({});

      for (const condition of this.extraConditions) {
        const exists =
          this.currentConditions.filter(
            (extra: any) => extra.facturable.id == condition.id,
          ).length > 0;

        this.formExtras.addControl(condition.id, this.fb.control(exists));
      }

      (this.formExtras);

      this.baseConditions = await this.getBySerConditions();

      this.detector.reattach();
      this.detector.detectChanges();
    });
  }

  sendToAproval() {
    const request_body: RequestAproval = {
      contract_id: this.contract.id,
      typeOfContract: 1,
      conditions: this.baseConditions.map((condition) => condition.id),
      extra_conditions: this.extraConditions
        .filter((condition) => this.formExtras.value[condition.id])
        .map((condition) => condition.id),
    };

    this.sendRequestAproval(request_body).then(() => {
      this.messageSvc.add({
        severity: 'success',
        summary: 'Solicitud enviada',
        detail: 'La solicitud de aprobación ha sido enviada correctamente',
      });
      this.location.back();
    });
  }

  public getPriceForCondition(condition: any) {
    const c = this.currentConditions.filter(
      (c) => c.facturable.id == condition.id,
    )[0];

    if (c) {
      let cx = '';
      switch (c.factor) {
        case 0:
          cx = 'por Expediente';
          break;
        case 1:
          cx = 'por Inspección';
          break;
        case 2:
          cx = 'Fijo';
      }

      return c.qty + `€ (${cx})`;
    }

    return 'No facturable';
  }

  private async getBySerConditions() {
    return await lastValueFrom(
      this.http.get<any>(`${environment.api}contracts/conditions-by-service`),
    );
  }

  private async getExtraConditions() {
    return await lastValueFrom(
      this.http.get<any[]>(
        `${environment.api}contracts/conditions-contract-extras`,
      ),
    );
  }

  private async getCurrentConditions() {
    return await lastValueFrom(
      this.http.get<any[]>(
        `${environment.api}contracts/conditions-contract-active/byser/${this.contract.id}`,
      ),
    );
  }

  private async sendRequestAproval(request: RequestAproval) {
    return await lastValueFrom(
      this.http.post(`${environment.api}contracts/request-reapproval`, request),
    );
  }
}
