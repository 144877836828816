
<div class="m-10">
    <!--Main Administracion-->
    <!--Div con Titulo y boton añadir usuario-->
    <div class="flex flex-row-reverse w-full mt-14 mb-4">
        <p-button pRipple *ngIf="!embed"  [disabled]="inserting" [label]="'admin.addUser' | translate" icon="pi pi-plus" iconPos="left" [routerLink]="['/account/company/users']" [queryParams]="{owner_id: owner_id, embed: embed}" size="small"></p-button>
        <p-button pRipple *ngIf="embed"  [disabled]="inserting" [label]="'admin.addUser' | translate" icon="pi pi-plus" iconPos="left" [routerLink]="['/users/company/users']" [queryParams]="{owner_id: owner_id, embed: embed}" size="small"></p-button>

        <div *ngIf="embed" class="w-full mr-8 flex border-b-2 border-b-navy-60">
          <h1 class="text-am-primary-turquoise-100 font-bold">{{ 'ui.tables.profile.empresa.usuarios' | translate }}</h1>
        </div>

    </div>
    <p-table #dt class="" [value]="users" [paginator]="true" [rows]="10" [totalRecords]="totalRecords"
            [loading]="loading" sortField="firstName" sortOrder="1"
            [rowHover]="true"
            [showCurrentPageReport]="true"
            [globalFilterFields]="['firstName', 'email', 'role']"
            currentPageReportTemplate="{currentPage} de {totalPages}"
            >

        <ng-template pTemplate="header">
            <tr>
                <th pSortableColumn="firstName">{{ 'ui.tables.profile.empresa.tabla.nombre' | translate}}<p-sortIcon field="firstName"></p-sortIcon></th>
                <th pSortableColumn="email">{{ 'ui.tables.profile.empresa.tabla.email' | translate}} <p-sortIcon field="email"></p-sortIcon></th>
                <th pSortableColumn="role">{{ 'ui.tables.profile.empresa.tabla.rol' | translate}}<p-sortIcon field="role"></p-sortIcon></th>
                <th class="w-16"></th>
                <th class="w-16"></th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-data>
            <tr>
                <td class="font-semibold">{{ data.firstName }} {{ data.lastName }}</td>
                <td>{{ data.email }}</td>
                <td>{{ data.role }}</td>
                <td>
                  <button pButton pRipple type="button" icon="pi pi-trash" class="p-button-text p-button-rounded p-button-plain " (click)="deleteUser(data)"></button>
                </td>
                <td>
                    <button *ngIf="!embed" pButton pRipple type="button" icon="pi pi-chevron-right" class="p-button-text p-button-rounded p-button-plain "
                    [routerLink]="['/users/company/users']" [queryParams]="{owner_id: owner_id, id: data.id}">
                    </button>
                    <button *ngIf="embed" pButton pRipple type="button" icon="pi pi-chevron-right" class="p-button-text p-button-rounded p-button-plain "
                    [routerLink]="['/users/company/users']" [queryParams]="{owner_id: owner_id, id: data.id, embed: true}">
                    </button>

                </td>
            </tr>
        </ng-template>


        <ng-template pTemplate="emptymessage" let-columns let-frozen="frozen">
            <tr>
                <td colspan="4"><div class="text-center p-10">{{ 'ui.general.noresults' | translate}}</div></td>
            </tr>
        </ng-template>

        <ng-template pTemplate="sorticon" let-number>
            <i *ngIf="number == 0" class="pi pi-sort" style="opacity:0.2"></i>
            <i *ngIf="number == 1" class="pi pi-sort-down"></i>
            <i *ngIf="number == -1" class="pi pi-sort-up"></i>
        </ng-template>

    </p-table>
</div>


<p-confirmDialog key="delete_client_user" #cdu [style]="{ width: '50vw' }">
  <ng-template pTemplate="header">
    <h3 class="font-bold">Eliminar</h3>
  </ng-template>
  <ng-template pTemplate="message">

    <div style="width:100%" class="flex flex-col justify-between text-sm text-center font-semibold h-full w-full">
      <div>
        {{'admin.sureToDelete1' | translate}}<strong>{{user?.email}}</strong>{{'admin.sureToDelete2' | translate}}
      </div>
      <div>
        {{'admin.sureToDelete3' | translate}}
      </div>
    </div>
  </ng-template>
  <ng-template pTemplate="footer">
    <div class="flex flex-row mt-6">
      <p-button type="button" [label]="'Cancelar'" (click)="cdu.reject()" size="small" [outlined]="true"></p-button>
      <p-button type="button" [label]="'Eliminar'" (click)="cdu.accept()" size="small" severity="danger"></p-button>
    </div>
  </ng-template>
</p-confirmDialog>


<p-confirmDialog #cd key="delete"  [style]="{ height: '60vh', width: '50vw' }">
  <ng-template pTemplate="header">
      <h3 class="font-bold">{{'admin.deleteUser' | translate}}</h3>
  </ng-template>
  <ng-template pTemplate="message">
      <div style="width:100%" class="flex flex-col justify-between text-sm text-center font-semibold h-full w-full">
        <div class=" flex flex-col p-input-underline">
          <label class="mb-6">{{'admin.select_user' | translate}}<strong> {{user?.email}}</strong></label>
          <app-user-input class="h-8" [control]="f.get('user')"></app-user-input>
        </div>
        <div>
          <div>
            {{'admin.sureToDelete1' | translate}}<strong>{{user?.email}}</strong>{{'admin.sureToDelete2' | translate}}
          </div>
          <div>
              {{'admin.sureToDelete3' | translate}}
          </div>
        </div>
      </div>
  </ng-template>
  <ng-template pTemplate="footer">
    <div class="flex flex-row">
      <p-button type="button" [label]="'admin.cancel' | translate" (click)="cd.reject()" size="small" [outlined]="true"></p-button>
      <p-button [disabled]="f.get('user').value == null || f.get('user').value.id == user?.id"  type="button" [label]="'admin.deleteUser' | translate" (click)="cd.accept()" size="small" severity="danger"></p-button>
    </div>
  </ng-template>
</p-confirmDialog>
