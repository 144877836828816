import { HttpClient } from '@angular/common/http';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  ConfirmEventType,
  ConfirmationService,
  FilterMetadata,
  MessageService
} from 'primeng/api';
import { Table } from 'primeng/table';
import { lastValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Inspection } from 'src/interfaces/inspections';
import {
  PermissionScope,
  PermissionType,
  User,
  UserService,
} from '../../services/user.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-inspections-list',
  templateUrl: './inspections-list.component.html',
  styleUrls: ['./inspections-list.component.scss'],
})
export class InspectionsListComponent implements OnInit, AfterViewInit {
  @ViewChild('dt') table?: Table;

  data?: any[];
  totalRecords?: number;
  loading?: boolean;

  inspections: Inspection[] = [];

  term: string = '';
  fromDate!: Date;
  toDate!: Date;
  page: number = 0;
  limit: number = 10;

  @Input() recordId?: number;

  @Input() geometry?: any = null;

  @Output() onSelected = new EventEmitter<any>();

  requestUrl: string = '';

  user?: User;
  canEdit: boolean = false;
  canDelete: boolean = false;
  canView: boolean = false;
  canViewAll: boolean = false;

  is_filtered: boolean = false;
  owner_id?: string = '';

  selectedLanguage: string = 'es';

  constructor(
    private http: HttpClient,
    private confirmationService: ConfirmationService,
    private userSvc: UserService,
    private messageSvc: MessageService,
    private detector: ChangeDetectorRef,
    private translate: TranslateService,
  ) {}
  ngAfterViewInit(): void {
    //this.detector.detach();
    //this.load();
    //this.detector.reattach();
    //this.detector.detectChanges();
  }

  ngOnInit() {
    this.user = this.userSvc.getUser();

    this.selectedLanguage = this.translate.defaultLang;

    this.canEdit =
      this.user?.hasPermission(
        PermissionScope.INSPECTIONS,
        PermissionType.EDIT,
      ) || false;
    this.canDelete =
      this.user?.hasPermission(
        PermissionScope.INSPECTIONS,
        PermissionType.DELETE,
      ) || false;
    this.canView =
      this.user?.hasPermission(
        PermissionScope.INSPECTIONS,
        PermissionType.VIEW,
      ) || false;
    this.canViewAll =
      this.user?.hasPermission(
        PermissionScope.INSPECTIONS,
        PermissionType.VIEW_ALL,
      ) || false;

    this.owner_id = this.user?.owner_id;

    if (this.owner_id == '') {
      this.canEdit = false;
      this.canDelete = false;
    }

    //this.load();
  }

  async buscarInspecciones() {
    this.loading = true;
    this.is_filtered = true;

    console.log(this.fromDate);
    console.log(this.toDate);

    if (
      this.fromDate == null &&
      this.toDate == null &&
      this.term == '' &&
      (this.geometry === undefined || this.geometry === null)
    ) {
      this.loading = false;
      this.is_filtered = false;
      await this.load();
      return;
    }

    const conditions = [];

    if (this.fromDate != null) {
      conditions.push(`fromDate=${this.fromDate.valueOf()}`);
    }

    if (this.toDate != null) {
      conditions.push(`toDate=${this.toDate.valueOf()}`);
    }

    if (this.term != '') {
      conditions.push(`searchTerm=${this.term}`);
    }

    if (this.geometry !== undefined && this.geometry !== null) {
      conditions.push(`geometry=${JSON.stringify(this.geometry.geometry)}`);
    }

    this.requestUrl = `${environment.api}inspection/search?record=${this.recordId}&${conditions.join('&')}`;

    console.log(this.requestUrl);

    const result = await lastValueFrom(this.http.get<any>(this.requestUrl));

    console.log(result);

    this.data = result?.elements || [];

    this.data?.forEach(
      (item) => (item.date_inspection = new Date(item.date).toISOString()),
    );

    console.log(this.data);

    this.totalRecords = result.list_size || 0;
    this.loading = false;
  }

  async load(event?: any) {
    console.log(event);

    console.log('loading data into table');

    this.loading = true;
    this.is_filtered = false;

    this.page = 0;
    this.limit = 10;

    this.page = event ? event.first / event.rows : 0;
    this.limit = event ? event.rows : 10;

    const sortField = event
      ? event.sortField
      : this.table?._sortField || undefined;
    const sortOrder =
      (event
        ? event.sortOrder === 1
          ? 'asc'
          : 'desc'
        : this.table?._sortOrder === 1
          ? 'asc'
          : 'desc') || 'asc';
    const searchTerm =
      event?.globalFilter ||
      (this.table?.filters['global'] as FilterMetadata)?.value ||
      '';

    //const recordId = this.recordId || '';

    this.data = [];
    this.totalRecords = 0;

    this.requestUrl = `${environment.api}inspection?record=${this.recordId}&page=${this.page}&limit=${this.limit}${sortField !== undefined ? '&sortField=' + sortField : ''}&sortOrder=${sortOrder}&searchTerm=${searchTerm}`;

    console.log(this.requestUrl);

    const result = await lastValueFrom(this.http.get<any>(this.requestUrl));

    console.log(result);

    this.data = result?.elements || [];
    this.totalRecords = result?.list_size || 0;

    this.loading = false;
  }

  selected(data: any) {
    this.onSelected.emit(data);
  }


  showTransfer(data: any) {
    //console.log(this.user);
    //console.log(data);

    if (this.user !== undefined && this.user.role == 'ADMIN') {
      return true;
    }

    return this.user !== undefined && (this.user.id == data?.inspector.id) ? true : false;

  }

  async markAsTransferable(event: any, data: any) {
    event.stopPropagation();

    console.log(data);

    //data.transfer = !data.transfer;
    const result: any = await lastValueFrom(this.http.get(`${environment.api}/inspection/transfer/count/${data.inspector.id}`));

    console.log(result)

    if ((result.count + 1) > 50 && data.transfer) {
      this.messageSvc.add({
        severity: 'error',
        summary: 'Error',
        detail: 'El inspector ya tiene asignado su cupo de inspecciones offline',
      });
      data.transfer = false;
      return;
    }

    // Call API to update transfer field
    const url = `${environment.api}/inspection/transfer/update/${data.agForestId}`;
    console.log(url);

    await lastValueFrom(this.http.put(`${environment.api}/inspection/transfer/update/${data.agForestId}`, {transfer: data.transfer}));
  }

  async delete(ev: Event, data: any) {
    ev.stopPropagation();

    this.confirmationService.confirm({
      key: 'delete',
      accept: async () => {
        this.loading = true;

        console.log(data);

        await lastValueFrom(
          this.http.delete(environment.api + 'inspection/' + data?.agForestId),
        );

        if (this.is_filtered) {
          await this.buscarInspecciones();
        } else {
          await this.load();
        }

        this.loading = false;
      },
      reject: (_type: ConfirmEventType) => {},
    });
  }
}
