
<div class="flex flex-col min-h-screen overflow-hidden">
    <p-toast position="bottom-right" ></p-toast>
    <div class="flex-grow">
        <app-header *ngIf="!embedded"></app-header>
        <router-outlet (activate)="md()"></router-outlet>
    </div>
    <app-footer *ngIf="!embedded"></app-footer>
</div>


