import {
  ApplicationRef,
  ComponentFactoryResolver,
  Injectable,
  Injector,
} from '@angular/core';
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';

@Injectable({
  providedIn: 'root',
})
export class PdfGeneratorService {
  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private appRef: ApplicationRef,
    private injector: Injector,
  ) {}

  async generatePdf(html: any, filename: string): Promise<void> {
    const pdf = new jsPDF('p', 'pt', 'a4');
    const options = {
      background: 'white',
      scale: 3,
    };
    await html2canvas(html as HTMLElement, options)
      .then((canvas) => {
        const imgData = canvas.toDataURL('image/png');
        const bufferX = 0;
        const bufferY = 0;
        const imgProps = pdf.getImageProperties(imgData);
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
        pdf.addImage(
          imgData,
          'PNG',
          bufferX,
          bufferY,
          pdfWidth,
          pdfHeight,
          undefined,
          'FAST',
        );
        return pdf;
      })
      .then((pdf) => {
        pdf.output('dataurlnewwindow');
        pdf.save(filename + '.pdf');
      });
  }
}
