import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormGroup } from '@angular/forms';

export type Question = {
  pregunta: string;
  key: string;
  responses: { response: string; score: number }[];
  response: string;
  score: number;
};

export type Response = {
  response: string;
};

export type Responses = {
  phase1: Response[];
  phase2: Response[];
  phase3: Response[];
};

@Component({
  selector: 'app-popupfichainsp',
  templateUrl: './popupfichainsp.component.html',
  styleUrls: ['./popupfichainsp.component.scss'],
})
export class PopupfichainspComponent implements OnChanges {
  @Input() fecha: string = '';
  @Input() info_num: string = '';

  @Input() responses?: Responses;
  @Input() index: number = 0;

  @Output() closeReport: EventEmitter<void> = new EventEmitter();
  @Output() saveReport: EventEmitter<Question[]> = new EventEmitter<
    Question[]
  >();

  questionsPhase1: Question[] = [];
  questionsPhase2: Question[] = [];
  questionsPhase3: Question[] = [];

  @Input() form!: FormGroup;

  totalScore = 0;

  allQuestions: Question[] = [];

  currentPhase = 1;

  constructor(private detector: ChangeDetectorRef) {
    this.questionsPhase1.push({
      pregunta: 'inspections.equipmentLocation',
      key: 'risk_location',
      responses: [
        { response: 'inspections.outdoorOrEncapsulated', score: 0 },
        { response: 'inspections.occasionallyAccessible', score: 2 },
        { response: 'inspections.largeAndWellVentilated', score: 3 },
        { response: 'inspections.between2501000', score: 7 },
        { response: 'inspections.lessThan250', score: 17 },
      ],
      response: '',
      score: 0,
    });

    this.questionsPhase1.push({
      pregunta: 'inspections.extentQuantity',
      key: 'risk_quantity',
      responses: [
        { response: 'inspections.smallQuantities', score: 0 },
        { response: 'inspections.lessThan10', score: 3 },
        { response: 'inspections.between1150', score: 7 },
        { response: 'inspections.moreThan51', score: 10 },
      ],
      response: '',
      score: 0,
    });
    this.questionsPhase1.push({
      pregunta: 'inspections.accessibility',
      key: 'risk_accessibility',
      responses: [
        { response: 'inspections.inaccessible', score: 0 },
        { response: 'inspections.difficultyAccessible', score: 2 },
        { response: 'inspections.easyAccessible', score: 5 },
        { response: 'inspections.highAccessible', score: 10 },
      ],
      response: '',
      score: 0,
    });
    this.questionsPhase1.push({
      pregunta: 'inspections.potentiallyExposed',
      key: 'risk_number_exposed',
      responses: [
        { response: 'inspections.none', score: 0 },
        { response: 'inspections.between13', score: 2 },
        { response: 'inspections.between410', score: 5 },
        { response: 'inspections.moreThan10', score: 10 },
      ],
      response: '',
      score: 0,
    });
    this.questionsPhase1.push({
      pregunta: 'inspections.technicalAccessibility',
      key: 'risk_technical_accesibility',
      responses: [
        { response: 'inspections.never', score: 0 },
        { response: 'inspections.occasionally', score: 0 },
        { response: 'inspections.frequent', score: 0 },
      ],
      response: '',
      score: 0,
    });
    this.questionsPhase2.push({
      pregunta: 'inspections.materialStatus',
      key: 'risk_material_status',
      responses: [
        { response: 'inspections.noDamage', score: 5 },
        { response: 'inspections.littleDamage', score: 15 },
        { response: 'inspections.mediumDamage', score: 25 },
        { response: 'inspections.greatDamage', score: 35 },
      ],
      response: '',
      score: 0,
    });
    this.questionsPhase2.push({
      pregunta: 'inspections.asbestosVariety',
      key: 'risk_variery',
      responses: [
        { response: 'inspections.onlyChrysotile', score: 3 },
        { response: 'inspections.amphibolesPresence', score: 5 },
      ],
      response: '',
      score: 0,
    });
    this.questionsPhase3.push({
      pregunta: 'inspections.friability',
      key: 'risk_fiability',
      responses: [
        { response: 'inspections.noFriability', score: 0.1 },
        { response: 'inspections.lowFriability', score: 0.5 },
        { response: 'inspections.mediumFriability', score: 0.75 },
        { response: 'inspections.highFriability', score: 1 },
      ],
      response: '',
      score: 0,
    });
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['form'] && changes['form'].currentValue) {
      this.loadQuestions(changes['form'].currentValue);
    }
  }

  loadQuestions(f: FormGroup) {
    this.form = f;
    this.questionsPhase1.forEach((q, _i) => {
      q.response = f?.get(q.key)?.value;
      const [_risk, wich, other] = q.key.split('_');
      q.score =
        f?.get(`risk_level_${wich}${other !== undefined ? '_' + other : ''}`)
          ?.value || 0;
    });

    this.questionsPhase2.forEach((q, _i) => {
      q.response = f?.get(q.key)?.value;
      const [_risk, wich, other] = q.key.split('_');
      q.score =
        f?.get(`risk_level_${wich}${other !== undefined ? '_' + other : ''}`)
          ?.value || 0;
    });

    this.questionsPhase3.forEach((q, _i) => {
      q.response = f?.get(q.key)?.value;
      const [_risk, wich, other] = q.key.split('_');
      q.score =
        f?.get(`risk_level_${wich}${other !== undefined ? '_' + other : ''}`)
          ?.value || 0;
    });
  }

  checkPhase1Responses() {
    return this.questionsPhase1.every((question) => question.response !== '');
  }

  checkPhase2Responses() {
    return this.questionsPhase2.every((question) => question.response !== '');
  }

  checkPhase3Responses() {
    return this.questionsPhase3.every((question) => question.response !== '');
  }

  nextStep() {
    this.currentPhase++;

    if (this.currentPhase === 4) {
      this.allQuestions = [...this.questionsPhase1, ...this.questionsPhase2];

      const factorPonderacion = this.questionsPhase3[0].score;
      const overallScore = this.allQuestions.reduce(
        (acc, question) => acc + question.score,
        0,
      );
      this.totalScore = overallScore * factorPonderacion;

      this.allQuestions = [...this.allQuestions, ...this.questionsPhase3];
    }

    if (this.currentPhase === 5) this.currentPhase = 4;
  }

  prevStep() {
    this.currentPhase--;

    if (this.currentPhase === 0) this.currentPhase = 1;
  }

  addScore(question: Question, response: { response: string; score: number }) {
    question.score = response.score;
  }

  finish() {
    this.saveReport.emit([
      ...this.questionsPhase1,
      ...this.questionsPhase2,
      ...this.questionsPhase3,
    ]);
  }

  close() {
    this.closeReport.emit();
  }

  //Listas preguntas formulario primera fase

  fichainsp: {
    estado_mca: string;
    tipo_amianto: string;
    valor_riesgo: string;
    acciones_respuesta: string;
    calendario_retirada: string;
    informe_plan_gestion: string;
  } = {
    estado_mca: '',
    tipo_amianto: '',
    valor_riesgo: '',
    acciones_respuesta: '',
    calendario_retirada: '',
    informe_plan_gestion: '',
  };
}
